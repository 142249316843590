import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import {loadFonts} from "./plugins/webfontloader";
import {initializeApp} from "firebase/app";
import {initEmulators} from "./helpers/emulatorHelper";
import {initClient} from "./client";
import {getAppFireBaseConfig} from "./helpers/deployments";
import {initConfig} from "./config";
// import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

loadFonts();
const firebaseConfig = getAppFireBaseConfig();


// Initialize Firebase
const app = initializeApp(firebaseConfig);
initEmulators();
// const analytics = getAnalytics(app);
initClient(app);
initConfig();

createApp(App)
    .use(router)
    .use(vuetify)
    .mount("#app");
