export class Cache<T> {
  constructor(public supplier:()=>Promise<T>, public ttlInSec = 60) {}
  async get():Promise<T> {
    if (this.cachedValue && new Date().getTime()<this.suppliedAt+this.ttlInSec*1000) {
      return Promise.resolve(this.cachedValue);
    } else {
      return this.inProgress??(this.inProgress=this.supplier().then((stuff) => {
        this.cachedValue = stuff;
        this.suppliedAt = new Date().getTime();
        this.inProgress = undefined;
        return stuff;
      }).catch((err) => {
        this.inProgress = undefined;
        throw err;
      }));
    }
  }

  getIfAvailable():T|undefined {
    if (new Date().getTime()<this.suppliedAt+this.ttlInSec*1000) {
      this.get();
    }
    return this.cachedValue;
  }

  private cachedValue?:T;
  private suppliedAt = 0;
  private inProgress? : Promise<T>;
}
