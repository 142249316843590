export enum State {
  Alabama = "Alabama",
  Alaska = "Aljaška",
  Arizona = "Arizona",
  Arkansas = "Arkansas",
  Colorado = "Colorado",
  Connecticut = "Connecticut",
  Delaware = "Delaware",
  DistrictOfColumbia = "District Of Columbia",
  Florida = "Florida",
  Georgia = "Georgia",
  Hawaii = "Havaj",
  Idaho = "Idaho",
  Illinois = "Illinois",
  Indiana = "Indiana",
  Iowa = "Iowa",
  California = "Kalifornie",
  Kansas = "Kansas",
  Kentucky = "Kentucky",
  Louisiana = "Louisiana",
  Maine = "Maine",
  Maryland = "Maryland",
  Massachusetts = "Massachusetts",
  Michigan = "Michigan",
  Minnesota = "Minnesota",
  Mississippi = "Mississippi",
  Missouri = "Missouri",
  Montana = "Montana",
  Nebraska = "Nebraska",
  Nevada = "Nevada",
  NewHampshire = "New Hampshire",
  NewJersey = "New Jersey",
  NewMexico = "Nové Mexiko",
  NewYork = "New York",
  NorthernDakota = "Severní Dakota",
  NorthernCarolina = "Severní Karolína",
  Ohio = "Ohio",
  Oklahoma = "Oklahoma",
  Oregon = "Oregon",
  Pennsylvania = "Pensylvánie",
  RhodeIsland = "Rhode Island",
  SouthernCarolina = "Jižní Karolína",
  SouthernDakota = "Jižní Dakota",
  Tennessee = "Tennessee",
  Texas = "Texas",
  Utah = "Utah",
  Vermont = "Vermont",
  Virginia = "Virginie",
  Washington = "Washington",
  Wisconsin = "Wisconsin",
  Wyoming = "Wyoming",
  WesternVirginia = "Západní Virginie",
}

export const states = [
  State.Alabama,
  State.Alaska,
  State.Arizona,
  State.Arkansas,
  State.Colorado,
  State.Connecticut,
  State.Delaware,
  State.DistrictOfColumbia,
  State.Florida,
  State.Georgia,
  State.Hawaii,
  State.Idaho,
  State.Illinois,
  State.Indiana,
  State.Iowa,
  State.California,
  State.Kansas,
  State.Kentucky,
  State.Louisiana,
  State.Maine,
  State.Maryland,
  State.Massachusetts,
  State.Michigan,
  State.Minnesota,
  State.Mississippi,
  State.Missouri,
  State.Montana,
  State.Nebraska,
  State.Nevada,
  State.NewHampshire,
  State.NewJersey,
  State.NewMexico,
  State.NewYork,
  State.NorthernDakota,
  State.NorthernCarolina,
  State.Ohio,
  State.Oklahoma,
  State.Oregon,
  State.Pennsylvania,
  State.RhodeIsland,
  State.SouthernCarolina,
  State.SouthernDakota,
  State.Tennessee,
  State.Texas,
  State.Utah,
  State.Vermont,
  State.Virginia,
  State.Washington,
  State.Wisconsin,
  State.Wyoming,
  State.WesternVirginia,
];
export const stateAbbreviations:Map<State, string> = new Map(
    [
      [State.Alabama, "AL"],
      [State.Alaska, "AK"],
      [State.Arizona, "AZ"],
      [State.Arkansas, "AR"],
      [State.California, "CA"],
      [State.Colorado, "CO"],
      [State.Connecticut, "CT"],
      [State.Delaware, "DE"],
      [State.Florida, "FL"],
      [State.Georgia, "GA"],
      [State.Hawaii, "HI"],
      [State.Idaho, "ID"],
      [State.Illinois, "IL"],
      [State.Indiana, "IN"],
      [State.Iowa, "IA"],
      [State.Kansas, "KS"],
      [State.Kentucky, "KY"],
      [State.Louisiana, "LA"],
      [State.Maine, "ME"],
      [State.Maryland, "MD"],
      [State.Massachusetts, "MA"],
      [State.Michigan, "MI"],
      [State.Minnesota, "MN"],
      [State.Mississippi, "MS"],
      [State.Missouri, "MO"],
      [State.Montana, "MT"],
      [State.Nebraska, "NE"],
      [State.Nevada, "NV"],
      [State.NewHampshire, "NH"],
      [State.NewJersey, "NJ"],
      [State.NewMexico, "NM"],
      [State.NewYork, "NY"],
      [State.NorthernCarolina, "NC"],
      [State.NorthernDakota, "ND"],
      [State.Ohio, "OH"],
      [State.Oklahoma, "OK"],
      [State.Oregon, "OR"],
      [State.Pennsylvania, "PA"],
      [State.RhodeIsland, "RI"],
      [State.SouthernCarolina, "SC"],
      [State.SouthernDakota, "SD"],
      [State.Tennessee, "TN"],
      [State.Texas, "TX"],
      [State.Utah, "UT"],
      [State.Vermont, "VT"],
      [State.Virginia, "VA"],
      [State.Washington, "WA"],
      [State.WesternVirginia, "WV"],
      [State.Wisconsin, "WI"],
      [State.Wyoming, "WY"],
    ]);

export enum Party {
  Republican = "Republican",
  Democrat = "Democrat",
}

export const parties = [Party.Democrat, Party.Republican];

export enum Chamber {
  Senate = "Senate",
  House = "House",
}
export const chambers = [Chamber.Senate, Chamber.House];

export enum BodyType {
  Senate = "Senate",
  House = "House",
  Government = "Government",
  Lobby = "Lobby",
  TCK = "TCK",
  Other = "Other",
}

export const bodyTypes = [
  BodyType.Government,
  BodyType.House,
  BodyType.Lobby,
  BodyType.Other,
  BodyType.Senate,
  BodyType.TCK,
];

export enum LobbyType {
  Np = "Non-profit",
  Corp = "Corporation",
  Un = "Union",
}

export const lobbyTypes=[LobbyType.Corp, LobbyType.Np, LobbyType.Un];

export const potusCandidateRole = {id: "POTUS-candidate", name: "Kandidát na prezidenta"};

export const stableAdditionalRoles = [
  potusCandidateRole,
];
