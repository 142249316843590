import {MakConfig} from "./config";
import {BodyType, Chamber, LobbyType, Party, State} from "./mak-facts";


export enum AccessLevel {
    Nobody = 0,
    LoggedIn = 1,
    Attendee = 2,
    Organizer = 3,
    Admin = 4,
}

export enum AccountType {
  Entity = "entity",
  Personal = "personal",
  Source = "source",
  Sink = "sink",
}

export interface User {
  id: string;
  name: string;
  email: string;
  pic?: string;
  originalIdentity?: {
    name: string;
    email: string;
  };
  accessLevel: AccessLevel;
  orgTeams?: string[];
  position?: Position;
  cv?: CV;
  additionalRoles?: AdditionalRole[],
}

export interface AdditionalRole {
  name: string,
  id: string,
  influenceModifier?: number
}
export interface UserWithRoleOrder extends User {
  roleOrder: RoleOrder;
}
export interface UserWithPersonalInfo extends User {
  personalInfo: PersonalInfo;
}

export interface UserWithService extends User {
  service?: Service;
}

export enum Sex {
  Male = "Muž",
  Female = "Žena",
  Other = "Jiné",
}
export interface PersonalInfo {
  sex?: Sex,
  birthday: string,
  nationality: string,
  idNumber?: string,
  address: string,
  phone: string,
}

export interface RoleOrder {
  roleWish: RoleWish;
  roleReality?: RoleReality;
}

export interface RoleWish {
  primaryRoles?: Position[];
  secondaryRoleFilters?: PositionFilter[];
  coverLetter?: RichText;
  discriminationInfo?: DiscriminationInfo;
  presidentCandidate?: boolean;
}

export interface RichText {
  quillOps: any[];
}

export interface CV {
  text: RichText;
  approved?: boolean;
}

export interface EvaluationStats {
  roleWishCount: number;
  evaluatedCount: number;
  averageEvalCount: number;
}

export interface DiscriminationInfo {
  schoolYear: SchoolYear;
  attendedSimulationsCount: number;
  attendedCMAKsCount: number;
  youthPoliticalParty: boolean;
}

export enum SchoolYear {
  upToSecondOfHigh = "2. ročník SŠ a nižší",
  thirdOfHigh = "3. ročník SŠ",
  fourthOfHigh = "4. ročník SŠ",
  bachelor = "bakalářské studium",
  masters = "magisterské studium",
}
export interface RoleReality {
  kill?: Kill;
  evalCount: number;
  totalScore?: Score;
}

export interface Kill {
  killer: User
  killNote: string
}

export interface Evaluation {
  score?: Score;
  author: User;
  evaleeId: string;
}
export interface Score {
  grammar: number;
  structure: number;
  creativityAndRhetoric: number;
  credibility: number;
}

export const minimumEvalCount = 5;

export const NOBODY: User = {
  id: "",
  name: "",
  email: "",
  accessLevel: AccessLevel.Nobody,
  orgTeams: [] as string[],
};

export interface Address {
  streetAddress: string;
  city: string;
  postalCode: number;
}

export const filterTypes = ["committee", "party", "fraction", "body", "state", "lobbyType"];
export interface PositionFilter {
  type: "committee" | "party" | "fraction" | "body" | "state" | "lobbyType";
  value: string;
}

export interface Position {
  body: BodyType;
  identifier: string;
}

/**
 * @deprecated
 */
export interface Department {
  id: string;
  name: string;
  availableCommittees: Committee[];
}
export interface Committee {
  id: string;
  name: string;
  chamber: Chamber;
}

export interface PartisanFraction {
  id: string;
  name: string;
  party: Party;
}

export interface ChamberFraction {
  id: string;
  name: string;
  chamber: Chamber;
}


export interface LobbyOrganization {
  id: string;
  type: LobbyType;
  name: string;
  headCount: number;
}

export interface MediaOrganization {
  id: string;
  name: string;
  headCount: number;
  leadOrgRoleId: string;
}
export interface PartisanPosition extends Position {
  party: Party;
}
export interface CommitteePosition extends Position {
  committee: Committee;
}

export interface MultiCommitteePosition extends Position {
  availableCommittees: Committee[];
}
export interface StatePosition extends Position {
  state: State;
}

export interface SenatePosition
  extends PartisanPosition,
    CommitteePosition,
    StatePosition {
  body: BodyType.Senate;
  fraction: PartisanFraction;
  secondaryFraction?: ChamberFraction;
}

export interface HousePosition
  extends PartisanPosition,
    CommitteePosition,
    StatePosition {
        body: BodyType.House;
  fraction: PartisanFraction;
  secondaryFraction?: ChamberFraction
}

export interface LobbyistPosition extends Position, MultiCommitteePosition {
  body: BodyType.Lobby;
  organization: LobbyOrganization;
}

/**
 * @deprecated
 */
export interface MinisterPosition
  extends PartisanPosition,
    MultiCommitteePosition {
    body:BodyType.Government;
    department: Department;
}

export interface GovernmentPosition extends MultiCommitteePosition {
  body:BodyType.Government,
  name: string,
}

export interface GovernmentPoliticalPosition extends GovernmentPosition, PartisanPosition, MultiCommitteePosition {
  body:BodyType.Government,
}

export interface TckPosition extends Position {
  body: BodyType.TCK;
  mediaOrg: MediaOrganization;
}

export interface OrgPosition extends Position {
    name: string;
    orgPosition: true;
    partisanFraction?: PartisanFraction;
    chamberFraction?: ChamberFraction;
    committee?: Committee;
    state?: State;
    party?: Party;
    ratingDutyFilters?: PositionFilter[];
}

export interface OrgBranch {
    id: string,
    name: string,
    teams: OrgTeam[],
}
export interface OrgTeam {
  id: string,
  name: string,
  evaluationDuty: boolean,
  accountingDuty: boolean,
  // Registration duty is a vague name, but I haven't been able to find out what the registration team does exactly, so it corresponds to team name
  registrationDuty: boolean
}
export interface Rating {
  activityScore: number,
  authenticityScore: number
}

export interface UserWithRating extends User {
  rating: UserRating,
}

export interface UserRating {
  id: string, // User ID
  dailyRatings: Rating[],
  influence: number,
  futureChances: number,
  final: boolean
}

export function emptyRating(userId:string):UserRating {
  return {
    id: userId,
    dailyRatings: [
      {
        activityScore: -1,
        authenticityScore: -1,
      },
      {
        activityScore: -1,
        authenticityScore: -1,
      },
      {
        activityScore: -1,
        authenticityScore: -1,
      },
    ],
    influence: -1,
    futureChances: -1,
    final: false,
  };
}

export interface RatingHints {
  currentAccountBalance: number,
  fractionContribution: number,
  secondaryFractionContribution: number,
  partyContribution: number,
  nonFiscalMeans: NonFiscalMeans[]
}

export interface Model{
    governingParty: Party,
    positions:Position[],
    positionById(id:string):Position|undefined,
    departments: Department[],
    mediaOrganizations: MediaOrganization[],
    lobbyOrganizations: LobbyOrganization[],
    committees: Committee[],
    committeeById(id:string):Committee|undefined;
    partisanFractions: PartisanFraction[],
    chamberFractions: ChamberFraction[],
    fractionById(id:string):PartisanFraction|ChamberFraction|undefined;
    orgPositions: OrgPosition[],
    orgBranches: OrgBranch[],
    orgTeams: OrgTeam[],
    orgTeamById(id:string):OrgTeam|undefined,
    nonPersonalAccounts: Account[],
    additionalRoles: AdditionalRole[],
    finesAccountId: string,
    campaignsAccountId: string,
    investigatorPositionIds: string[],

    defaultConfig: MakConfig
}

export interface WishWithEval {
  roleWish: RoleWish,
  evaluation: Evaluation,
}

export interface Service {
  payments: Payment[],
  correctingPayments: CorrectingPayment[],
  serviceOrder: ServiceOrder,
}

export interface ServiceOrder {
  dormOrder?: DormOrder,
  lunches?: LunchOrder,
  publicTransport: boolean,
  variableCode: string,
}

export interface LunchOrder {
  specialNotes?: string, // Possible vegans/allergies...
}

export interface DormOrder {
  preferredRoommates: string,
  preferredCellmate: string,
  dormPreference: string,
  extraNight: boolean,
}

export interface Payment {
  amount: number,
  fromAccount?: string,
  variableCode: string,
  date: string,
}

// Intended for possible substractions due to faulty accounting/refunds
// Currently reserved for future use
export interface CorrectingPayment {
  amount: number,
  note: string,
}

// Not sure if this will ever need to be extended, currently just to keep the model structure
export interface Dorm {
  name: string,
}

export interface PriceList {
  pricingGroup: AccessLevel,
  attendanceFee: number,
  fiveNights: number,
  extraNight: number,
  lunches: number,
  publicTransport: number,
}

export interface ServiceModel {
  dorms: Dorm[],
  prices: PriceList[],
}

export interface FinancialModel {
  accounts: Account[],
}

// Intended as a wrapper object to return to FE, not a database entity. Server will fill out all fields every time a request is made
export interface Account {
  id: string,
  name: string,
  type: AccountType,
  isTransparent: boolean,
  /** list of position identifiers (can be mix of attendee, organizer, additional) */
  accessFilter: string[],
  holder?: User,
}

export interface AccountState extends Account {
  transactions: TransactionEnriched[],
  frozen: boolean,
  balance: number
}

export interface Transaction {
  originAccount?: string, // Id of sender
  originAccountName?: string, // Id of sender
  targetAccount: string, // Id of reciever
  targetAccountName: string, // Id of sender
  amount: number,
  freeze?: boolean,
  timestamp: number
  transactionId: string,
  siblingTransaction?: string,
  note?: string,
  requestorId: string,
  requestorName?: string,
}

export interface TransactionEnriched extends Transaction{
  siblingTransactionDetail?:Transaction;
}

export interface MediaPriceList {
  items: {mediaOrgId: string, pricePerSecond: number}[]
}

export interface NonFiscalMeans {
  id: string,
  name: string,
  influenceModifier: number,
  ownerPositionId: string,
  assignedToUser?: string,
  assignmentTimestamp?: number,
  assignerUser?: User,
  description?: string,
}
