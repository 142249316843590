// Dev environment
export const DEV_ENVIRONMENT = process.env.VUE_APP_DEV_ENVIRONMENT || false;

/*
    Emulator routes
*/
export const EMULATOR_HOST = process.env.VUE_APP_EMULATOR_HOST || "localhost";
export const AUTH_EMULATOR = process.env.VUE_APP_AUTH_EMULATOR || "";
export const DATABASE_EMULATOR_PORT = Number(process.env.VUE_APP_DATABASE_EMULATOR_PORT);
export const FIRESTORE_EMULATOR_PORT = Number(process.env.VUE_APP_FIRESTORE_EMULATOR_PORT);
export const CLOUD_STORAGE_EMULATOR_PORT = Number(process.env.VUE_APP_CLOUD_STORAGE_EMULATOR_PORT);
export const FUNCTIONS_EMULATOR_PORT = Number(process.env.VUE_APP_FUNCTIONS_EMULATOR_PORT);
export const DEPLOYMENT = process.env.VUE_APP_DEPLOYMENT || "";


export const account = "CZ3720100000002302367464";
export const readableAccount = "2302367464/2010";
