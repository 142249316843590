
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {defineComponent} from "vue";
import {getSelf} from "./client";
import {store} from "./store";
import {RouteLocationRaw} from "vue-router";
export default defineComponent({
  name: "App",
  data() {
    return {
      store: store,
    };
  },
  mounted() {
    const auth = getAuth();

    onAuthStateChanged(auth, (u) => {
      if (u) {
        const currentRoute = this.$router.currentRoute;
        let targetRoute: RouteLocationRaw;
        if (currentRoute.value.path == "/") {
          targetRoute = "/main";
        } else {
          targetRoute = currentRoute.value;
        }
        getSelf().then((user) => store.me = user).then(() => this.$router.push(targetRoute));
      } else {
        this.$router.push("/");
      }
    },
    () => this.$router.push("/"));
  },
});
