// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import {createVuetify} from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";


export default createVuetify(
    {
      components,
      directives,
      theme: {
        themes: {
          light: {
            dark: false,
            colors: {
              primary: "#000091",
              secondary: "#CA0000",
              primary1: "#8080ff",
              primary2: "#b3b3ff",
              secondary3: "#ffe5e5",
              secondary2: "#ffb3b3",
              secondary1: "#ff8080",
            },
          },
        },
      },
    },
);

// import Vuetify, {VApp} from
