import {ModelSetup} from "./mak-setup";
import {generateModel} from "./mak-model";
import {BodyType, Chamber, LobbyType, Party, State, states} from "./mak-facts";
const committeesCsv = `komora,vybor
HR,Výbor pro národní bezpečnost (VNB)
,Výbor pro energetiku a hospodářství (VEH)
,Výbor pro sociální služby (VSS)
,Výbor pro dopravu a infrastrukturu (VDI)
,Vyšetřovací komise (Komise)
Sen,Výbor pro justici (VJ)
,Výbor pro zahraniční vztahy (VZV)
,Výbor pro ozbrojené složky (VOS)
,Výbor pro děti a rodinu (VDětR)`;

const partisanFractionsCsv=`FRAKCE,ID,PARTY
Klub za Svobodu,SVOB,REP
Nová Pravice,NOVPRAV,REP
Liberální Republikáni,LIBREP,REP
Modří Psi,MODROPES,DEM
Noví Demokraté,NOVDEM,DEM
Progresivisté,PROG,DEM`;

const houseFractionsCsv = `SEK FRAKCE
Klub pro finanční vzdělání a tvorbu bohatství (FLWCC)
Klub pro udržitelnou energii a životní prostředí (SEEC)
Klub pro veřejné zdraví (CPHC)
Anti-woke klub (AWC)
Klub sněmovních hispánců (CHC)
Klub pro moderní technologie (CHTC)
Bipartisan women's caucus (BWC)
Skupina pro posilování demokracie (TFSD)`;

const exeCsv = `Post (PROVISIONAL),legislativa 1,legislativa 2,legislativa 3,legislativa 4,legislativa 5,legislativa 6,legislativa 7,legislativa 8
Ministr spravedlnosti,Finacování církví (VJ),War Powers Act (VOS),Potraty (VDětR),Nelegální migrace (VNB),Poštovní služby (VDI),Mitigace rizik umělé inteligence (VJ),Bezpečnost internetových seznamek (VEH),
Ministr zahraničních věcí,Přístup k OAS a JA (VZV),Přístup k Saúdské Arábii (VZV),Pomoc Ukrajině (VOS),,Nelegální migrace (VNB),,,
Ministr financí,Finacování církví (VJ),Reforma IRS (VEH),Startupy (VEH),,,,,
Ministr obrany,Pomoc Ukrajině (VOS),War Powers Act (VOS),Psychedelika pro veterány (VNB),,,,,
Ministr pro národní bezpečnost,War Powers Act (VOS),Boj proti drogám (VSS),Bezpečnost internetových seznamek (VEH),Regulace střelných zbraní (VNB),Mitigace rizik umělé inteligence (VJ),Poštovní služby (VDI),Ochrana sousedství (VSS),Nelegální migrace (VNB)
Ministr zdravotnictví a sociálních služeb,Redukce obezity (VSS) ,Psychedelika pro veterány (VNB),Změna pohlaví u dětí (VDětR),Potraty (VDětR),Boj proti drogám (VSS),Psychedelika pro veterány (VNB),,
Ministr dopravy,Budoucnost Amtraku (VDI),Udržitelná letecká doprava (VDI),Poštovní služby (VDI),,Ochrana sousedství (VSS),,,
Ministr pro bydlení a městský rozvoj,Ochrana sousedství (VSS),Regulace střelných zbraní (VNB),Nelegální migrace (VNB),,,,,
Ministr práce,Nelegální migrace (VNB),Podpora startupů (VEH),,,Mitigace rizik umělé inteligence (VJ),,,
Ředitel kanceláře vědecké a technologické politiky,Podpora startupů (VEH),Mitigace rizik umělé inteligence (VJ),Bezpečnost internetových seznamek (VEH),,,,,
EPA,Udržitelná letecká doprava (VDI),Budoucnost Amtraku (VDI),,,Podpora startupů (VEH),Ochrana sousedství (VSS),,
Ředitel kanceláře zahraničního obchodu,Přístup k OAS a JA (VZV),Přístup k Saúdské Arábii (VZV),Pomoc Ukrajině (VOS),,,,,
Koordinátor zpravodajských služeb (DNI),War Powers Act (VOS),Nelegální migrace (VNB),Pomoc Ukrajině (VOS),,Mitigace rizik umělé inteligence (VJ),Poštovní služby (VDI),Regulace střelných zbraní (VNB),
Ředitel úřadu pro kontrolu potravin a léčiv (FDA),Redukce obezity (VSS) ,Boj proti drogám (VSS),Psychedelika pro veterány (VNB),,Podpora startupů (VEH),,Regulace střelných zbraní (VNB),
Ředitel úřadu pro děti a rodinu (ACF),Potraty (VDětR),Změna pohlaví u dětí (VDětR),Ochrana sousedství (VSS),,Podpora startupů (VEH),,Regulace střelných zbraní (VNB),`;

const lobbyCsv = `org,typ,legislativa 1,legislativa 2,legislativa 3,legislativa 4,legislativa 5,legislativa 6,legislativa 7,legislativa 8
Tinder,CORP,,,,,,,,
PhaRMA,CORP,,,,,,,,
Colt CZ Group,CORP,,,,,,,,
Boeing,CORP,,,,,,,,
Walmart,CORP,,,,,,,,
Microsoft,CORP,,,,,,,,
Union Pacific,CORP,,,,,,,,
American Psychological Association (AMA),CORP,,,,,,,,
ExxonMobil,CORP,,,,,,,,
National Association of Evangelicals,NGO,,,,,,,,
RAND,NGO,,,,,,,,
National Rifle Association of America,NGO,,,,,,,,
Women's Congressional Policy Institute,NGO,,,,,,,,
US Council of Muslim Organizations,NGO,,,,,,,,
ACLU,NGO,,,,,,,,
America's Homeowner Alliance,NGO,,,,,,,,
AIPAC,NGO,,,,,,,,
Greenpeace,NGO,,,,,,,,
Heritage Foundation,NGO,,,,,,,,
Service Employees International Union,UNION,,,,,,,,
AFL-CIO,UNION,,,,,,,,`;

const mediaOrganizationsCsv = `Stanice,Počet novinářů
FOX,7
CNN,7`;

const HRCsv= `Výbor,Strana,Frakce,Sek. Frakce,Stát
VNB,GOP,SVOB,AWC,Alabama
,GOP,SVOB,AWC,Louisiana
,GOP,SVOB,CHC,Nevada
,GOP,NOVPRAV,CHC,Nové Mexiko
,GOP,NOVPRAV,AWC,Utah
,GOP,NOVPRAV,TFSD,Ohio
,GOP,NOVPRAV,CPHC,New York
,GOP,LIBREP,FLWCC,Západní Virginie
,DEM,MODROPES,BWC,Pensylvánie
,DEM,MODROPES,CHC,Colorado
,DEM,NOVDEM,TFSD,Maryland
,DEM,NOVDEM,CPHC,Texas
,DEM,PROG,FLWCC,Oregon
,DEM,PROG,CPHC,Kentucky
,DEM,PROG,CHC,Kalifornie
VEH,GOP,SVOB,AWC,Georgia
,GOP,SVOB,FLWCC,Nebraska
,GOP,NOVPRAV,CHC,Florida
,GOP,NOVPRAV,CHTC,Wyoming
,GOP,NOVPRAV,BWC,Severní Dakota
,GOP,NOVPRAV,AWC,Missouri
,GOP,LIBREP,SEEC,Colorado
,GOP,LIBREP,FLWCC,Jižní Dakota
,DEM,MODROPES,FLWCC,Maryland
,DEM,NOVDEM,CHTC,Virginie
,DEM,NOVDEM,BWC,Connecticut
,DEM,NOVDEM,SEEC,Arizona
,DEM,NOVDEM,TFSD,Wisconsin
,DEM,PROG,FLWCC,Georgia
,DEM,PROG,CHC,New York
VSS,GOP,SVOB,AWC,Kentucky
,GOP,SVOB,BWC,Oklahoma
,GOP,SVOB,CPHC,Kansas
,GOP,SVOB,CHTC,Jižní Karolína
,GOP,NOVPRAV,AWC,Arkansas
,GOP,NOVPRAV,SEEC,Texas
,GOP,NOVPRAV,AWC,Iowa
,GOP,LIBREP,TFSD,Wisconsin
,DEM,MODROPES,TFSD,Michigan
,DEM,NOVDEM,CPHC,New Hampshire
,DEM,NOVDEM,CHC,New Jersey
,DEM,PROG,CHTC,Massachusetts
,DEM,PROG,CHC,Nevada
,DEM,PROG,CPHC,Delaware
,DEM,PROG,SEEC,Washington
VDI,GOP,SVOB,AWC,Mississippi
,GOP,SVOB,CHTC,Severní Karolína
,GOP,SVOB,FLWCC,Idaho
,GOP,NOVPRAV,CHTC,Kalifornie
,GOP,NOVPRAV,CPHC,Pensylvánie
,GOP,NOVPRAV,BWC,Montana
,GOP,NOVPRAV,SEEC,Michigan
,GOP,LIBREP,SEEC,Tennessee
,DEM,MODROPES,FLWCC,Illinois
,DEM,MODROPES,SEEC,Aljaška
,DEM,NOVDEM,CHTC,Washington
,DEM,NOVDEM,TFSD,Virginie
,DEM,NOVDEM,CPHC,Maine
,DEM,PROG,SEEC,Tennessee
,DEM,PROG,TFSD,Rhode Island
Komise,GOP,SVOB,AWC,Florida
,GOP,NOVPRAV,AWC,Indiana
,GOP,NOVPRAV,CHC,Illinois
,GOP,NOVPRAV,TFSD,Arizona
,GOP,LIBREP,BWC,Maine
,GOP,LIBREP,TFSD,Aljaška
,DEM,MODROPES,TFSD,Ohio
,DEM,MODROPES,BWC,Minnesota
,DEM,NOVDEM,FLWCC,Jižní Dakota
,DEM,NOVDEM,TFSD,Severní Karolína
,DEM,NOVDEM,CHC,Nové Mexiko
,DEM,PROG,TFSD,Mississippi
,DEM,PROG,BWC,Vermont
,DEM,PROG,SEEC,Havaj`;

const senatorsCsv = `Výbor ,Strana,Frakce,Stát
VJ,GOP,SVOB,Alabama
,GOP,SVOB,Utah
,GOP,SVOB,Jižní Karolína
,GOP,NOVPRAV,Kentucky
,GOP,NOVPRAV,Arkansas
,GOP,NOVPRAV,Florida
,GOP,LIBREP,Wyoming
,DEM,MODROPES,Michigan
,DEM,MODROPES,Západní Virginie
,DEM,NOVDEM,Oregon
,DEM,NOVDEM,New Hampshire
,DEM,NOVDEM,New York
,DEM,PROG,Colorado
,DEM,PROG,Rhode Island
VZV,GOP,SVOB,Missouri
,GOP,SVOB,Texas
,GOP,NOVPRAV,Ohio
,GOP,NOVPRAV,Jižní Dakota
,GOP,NOVPRAV,Kansas
,GOP,LIBREP,Aljaška
,GOP,LIBREP,Montana
,DEM,MODROPES,Pensylvánie
,DEM,MODROPES,Virginie
,DEM,NOVDEM,Washington
,DEM,NOVDEM,New York
,DEM,PROG,Havaj
,DEM,PROG,Delaware
,DEM,PROG,Arizona
VOS,GOP,SVOB,Louisiana
,GOP,SVOB,Iowa
,GOP,SVOB,Indiana
,GOP,NOVPRAV,Severní Karolína
,GOP,NOVPRAV,Tennessee
,GOP,LIBREP,Wisconsin
,GOP,LIBREP,Idaho
,DEM,MODROPES,Maryland
,DEM,MODROPES,Ohio
,DEM,NOVDEM,Minnesota
,DEM,NOVDEM,Nové Mexiko
,DEM,NOVDEM,Kalifornie
,DEM,PROG,New Jersey
,DEM,PROG,Massachusetts
VDětR,GOP,SVOB,Jižní Karolína
,GOP,SVOB,Mississippi
,GOP,SVOB,Oklahoma
,GOP,NOVPRAV,Severní Dakota
,GOP,NOVPRAV,Nebraska
,GOP,NOVPRAV,Kansas
,GOP,LIBREP,Maine
,DEM,MODROPES,Arizona
,DEM,NOVDEM,Connecticut
,DEM,NOVDEM,Nevada
,DEM,NOVDEM,Maryland
,DEM,PROG,Georgia
,DEM,PROG,Vermont
,DEM,PROG,Illinois`;


function parseCsv(csv: string, gravityColumns = false):Map<string, string>[] {
  const lines = csv.split("\n");
  const titles = lines[0].split(",").map((s)=>s.trim());
  lines.splice(0, 1);
  const lastLineFields = [...titles];
  return lines.map((line) => {
    const fields = line.split(",").map((s)=>s.trim());
    const result = new Map(fields.map((field, index) => [titles[index], (field==""&&gravityColumns)?lastLineFields[index]:field]));
    fields.forEach((field, index)=>{
      if (field) {
        lastLineFields[index] = field;
      }
    });
    return result;
  });
}

function parseNameWithID(name: string):[name:string, id:string] {
  if (!name || !name.includes("(")) {
    throw new Error(`cannot parse name with ID: ${name}`);
  }
  return [
    name.split("(")[0].trim(),
    name.split("(")[1].split(")")[0].trim(),
  ];
}

const lobbyTypeTranslations:{[key:string]:LobbyType} = {
  "NGO": LobbyType.Np,
  "CORP": LobbyType.Corp,
  "UNION": LobbyType.Un,
};
// Výbor,Strana,Frakce,Sek. Frakce,Stát
const representatives = parseCsv(HRCsv, true).map((fields, index)=>({
  committee: fields.get("Výbor")!,
  party: fields.get("Strana")=="DEM"?Party.Democrat:Party.Republican,
  partisanFraction: fields.get("Frakce")!,
  chamberFraction: fields.get("Sek. Frakce")!,
  state: fields.get("Stát")! as State,
}));

const senators = parseCsv(senatorsCsv, true).map((fields, index)=>({
  committee: fields.get("Výbor")!,
  party: fields.get("Strana")=="DEM"?Party.Democrat:Party.Republican,
  partisanFraction: fields.get("Frakce")!,
  state: fields.get("Stát")! as State,
}));

export const setup:ModelSetup = {
  governingParty: Party.Democrat,
  government: parseCsv(exeCsv, false).map((fields, index)=>(
    {
      id: `exe.${index}`,
      name: fields.get("Post (PROVISIONAL)")!,
      political: fields.get("Post (PROVISIONAL)")?.includes("Ministr")??false,
      committees: [1, 2, 3, 4, 5, 6, 7, 8].map((i)=>fields.get(`legislativa ${i}`))
          .filter((f)=>f?true:false)
          .map((legislativa)=>parseNameWithID(legislativa!)[1]),
    })
  ),
  committees: parseCsv(committeesCsv, true).map((fields)=>(
    {
      name: parseNameWithID(fields.get("vybor")!)[0],
      id: parseNameWithID(fields.get("vybor")!)[1],
      chamber: fields.get("komora")=="HR"? Chamber.House:Chamber.Senate,
    })
  ),
  partisanFractions: parseCsv(partisanFractionsCsv, true).map((fields)=>(
    {
      id: fields.get("ID")!,
      name: fields.get("FRAKCE")!,
      party: fields.get("PARTY") == "REP"? Party.Republican : Party.Democrat,
    })
  ),
  chamberFractions: [
    ...parseCsv(houseFractionsCsv, true).map((fields)=>(
      {
        name: parseNameWithID(fields.get("SEK FRAKCE")!)[0],
        id: parseNameWithID(fields.get("SEK FRAKCE")!)[1],
        chamber: Chamber.House,
      })
    ),
  ],

  lobbyOrganizations: parseCsv(lobbyCsv, false).map((fields, index)=>(
    {
      id: `lobby.${index}`,
      name: fields.get("org")!,
      type: lobbyTypeTranslations[fields.get("typ")!] as LobbyType,
      committees: [1, 2, 3, 4, 5, 6, 7, 8].map((i)=>fields.get(`legislativa ${i}`))
          .filter((f)=>f?true:false)
          .map((legislativa)=>parseNameWithID(legislativa!)[1]),
      headCount: 1,
    }
  )),

  orgLobbyOrganizations: [
    {
      id: "exPasztor",
      name: "Exprezident Pásztor",
      type: LobbyType.Corp,
      headCount: 1,
    },
  ],

  mediaOrganizations: parseCsv(mediaOrganizationsCsv, false).map((fields, index)=>(
    {
      id: fields.get("Stanice")!,
      name: fields.get("Stanice")!,
      leadOrgRole: {
        body: BodyType.TCK,
        name: `Ředitel stanice ${fields.get("Stanice")}`,
        id: `${fields.get("Stanice")}.boss`,
        ratingDuty: {filters: [{type: "body", value: BodyType.TCK}]},
      },
      headCount: 7,
    })
  ),
  states: states.map((state)=>(
    {
      state: state,
      senators: senators.filter((r)=>r.state==state).map((senator)=>(
        {
          party: senator.party,
          fractionId: senator.partisanFraction,
          committeeId: senator.committee,
        }
      )),
      representatives: representatives.filter((r)=>r.state==state).map((r)=>(
        {
          party: r.party,
          fractionId: r.partisanFraction,
          secondaryFractionId: r.chamberFraction,
          committeeId: r.committee,
        }
      )),
    }
  )),
  departments: [],
  democraticParty: {
    partyLeaderAdditionalRoles: [
      {id: "HMINOR", name: "Vůdce sněmovní menšiny"},
      {id: "SMAJOR", name: "Vůdce senátní většiny"},
      {id: "whipDem", name: "Demokratický whip"},
    ],
    partyLeaderOrgRoles: [
      {id: "ordDemLeadSenate", name: "President pro tempore", body: BodyType.Senate, party: Party.Democrat, ratingDuty: {filters: [{type: "body", value: BodyType.Senate}]}},
    ],
  },
  republicanParty: {
    partyLeaderAdditionalRoles: [
      {id: "SMINOR", name: "Vůdce senátní menšiny"},
      {id: "whipRep", name: "Republikánský whip"},
    ],
    partyLeaderOrgRoles: [
      {id: "SPEAKER", name: "Předseda sněmovny", body: BodyType.House, party: Party.Republican, ratingDuty: {filters: [{type: "body", value: BodyType.House}]}},
    ],
  },
  orgBranches: [
    {id: "HR", name: "Lidské zdroje", teams: [
      {id: "HR.evals", name: "Vyhodnocovači", evaluationDuty: true, accountingDuty: false, registrationDuty: false},
    ]},
    {id: "Finance", name: "Finance", teams: [
      {id: "Finance.accountants", name: "Účetní", evaluationDuty: false, accountingDuty: true, registrationDuty: false},
    ]},
    {id: "PR", name: "Veřejné vztahy", teams: [
      {id: "PR.registrations", name: "Registrační tým", evaluationDuty: false, accountingDuty: false, registrationDuty: true},
    ]},
  ],
  investigators: [
    {id: "orgInvestigator", name: "Zvláštní agentka FBI", body: BodyType.Lobby, ratingDuty: {filters: [{type: "body", value: BodyType.Lobby}]}},
    {id: "orgInvestigator2", name: "Zvláštní agent FBI", body: BodyType.Lobby, ratingDuty: {filters: [{type: "body", value: BodyType.Lobby}]}},
    {id: "USATT", name: "Státní žalobce", body: BodyType.Lobby, ratingDuty: {filters: [{type: "body", value: BodyType.Lobby}]}},
  ],
  otherOrgPositions: [
    {id: "POTUS", name: "Prezident", body: BodyType.Government, party: Party.Democrat, ratingDuty: {filters: [{type: "body", value: BodyType.Government}]}},
    {id: "VICE", name: "Viceprezident", body: BodyType.Government, party: Party.Democrat, ratingDuty: {filters: [{type: "body", value: BodyType.Government}]}},
    {id: "COS", name: "Chief of Staff", body: BodyType.Government, party: Party.Democrat, ratingDuty: {filters: [{type: "body", value: BodyType.Government}]}},
    {id: "NSA", name: "Poradce pro národní bezpečnost", body: BodyType.Government, party: Party.Democrat, ratingDuty: {filters: [{type: "body", value: BodyType.Government}]}},
    {id: "PRESS", name: "Tisková mluvčí Bíleho domu", body: BodyType.Government, party: Party.Democrat, ratingDuty: {filters: [{type: "body", value: BodyType.Government}]}},
    {id: "CVNB1", name: "Předsedající VNB, Progresivista", body: BodyType.House, party: Party.Democrat, fractionId: "PROG", secondaryFractionId: "CPHC", state: State.NewYork, ratingDuty: {filters: [{type: "committee", value: "VNB"}]}},
    {
      id: "CVNB2",
      name: "Předsedající VNB, Nová Pravice",
      body: BodyType.House,
      party: Party.Republican,
      fractionId: "NOVPRAV",
      secondaryFractionId: "CHC",
      state: State.California,
      ratingDuty: {
        filters: [{type: "committee", value: "VNB"}],
      },
    },
    {
      id: "CVNB3",
      name: "Předsedající VNB, Klub za Svobodu",
      body: BodyType.House,
      party: Party.Republican,
      fractionId: "SVOB",
      secondaryFractionId: "AWC",
      state: State.Kansas,
      ratingDuty: {
        filters: [{type: "committee", value: "VNB"}],
      },
    },
    {
      id: "CVEH1",
      name: "Předsedající VEH, Nový Demokrat",
      body: BodyType.House,
      party: Party.Democrat,
      fractionId: "NOVDEM",
      secondaryFractionId: "CHTC",
      state: State.Washington,
      ratingDuty: {
        filters: [{type: "committee", value: "VEH"}],
      },
    },
    {
      id: "CVEH2",
      name: "Předsedající VEH, Liberální Republikán",
      body: BodyType.House,
      party: Party.Republican,
      fractionId: "LIBREP",
      secondaryFractionId: "FLWCC",
      state: State.Michigan,
      ratingDuty: {
        filters: [{type: "committee", value: "VEH"}],
      },
    },
    {
      id: "CVEH3",
      name: "Předsedající VEH, Modrý Pes",
      body: BodyType.House,
      party: Party.Democrat,
      fractionId: "MODROPES",
      secondaryFractionId: "SEEC",
      state: State.Alaska,
      ratingDuty: {
        filters: [{type: "committee", value: "VEH"}],
      },
    },
    {
      id: "CVSS1",
      name: "Předsedající VSS, Modrý Pes",
      body: BodyType.House,
      party: Party.Democrat,
      fractionId: "MODROPES",
      secondaryFractionId: "CHC",
      state: State.Arizona,
      ratingDuty: {
        filters: [{type: "committee", value: "VSS"}],
      },
    },
    {
      id: "CVSS2",
      name: "Předsedající VSS, Klub za Svobodu",
      body: BodyType.House,
      party: Party.Republican,
      fractionId: "SVOB",
      secondaryFractionId: "AWC",
      state: State.Georgia,
      ratingDuty: {
        filters: [{type: "committee", value: "VSS"}],
      },
    },
    {
      id: "CVDI1",
      name: "Předsedající VDI, Progresivista",
      body: BodyType.House,
      party: Party.Democrat,
      fractionId: "PROG",
      secondaryFractionId: "BWC",
      state: State.Illinois,
      ratingDuty: {
        filters: [{type: "committee", value: "VDI"}],
      },
    },
    {
      id: "CVDI2",
      name: "Předsedající VDI, Nová Pravice",
      body: BodyType.House,
      party: Party.Republican,
      fractionId: "NOVPRAV",
      secondaryFractionId: "SEEC",
      state: State.Pennsylvania,
      ratingDuty: {
        filters: [{type: "committee", value: "VDI"}],
      },
    },
    {
      id: "CVK1",
      name: "Předsedající Vyšetřovací komise, Nový Demokrat",
      body: BodyType.House,
      party: Party.Democrat,
      fractionId: "NOVDEM",
      secondaryFractionId: "TFSD",
      state: State.Texas,
      ratingDuty: {
        filters: [{type: "committee", value: "Komise"}],
      },
    },
    {
      id: "CVK2",
      name: "Předsedající Vyšetřovací komise, Nová Pravice",
      body: BodyType.House,
      party: Party.Republican,
      fractionId: "NOVPRAV",
      secondaryFractionId: "BWC",
      state: State.Maine,
      ratingDuty: {
        filters: [{type: "committee", value: "Komise"}],
      },
    },
    {
      id: "CVJ1",
      name: "Předsedající VJ, Nový Demokrat",
      body: BodyType.Senate,
      party: Party.Democrat,
      fractionId: "NOVDEM",
      state: State.Minnesota,
      ratingDuty: {
        filters: [{type: "committee", value: "VJ"}],
      },
    },
    {
      id: "CVJ2",
      name: "Předsedající VJ, Nová Pravice",
      body: BodyType.Senate,
      party: Party.Republican,
      fractionId: "NOVPRAV",
      state: State.NorthernCarolina,
      ratingDuty: {
        filters: [{type: "committee", value: "VJ"}],
      },
    },
    {
      id: "CVZV1",
      name: "Předsedající VZV, Modrý Pes",
      body: BodyType.Senate,
      party: Party.Democrat,
      fractionId: "MODROPES",
      state: State.Michigan,
      ratingDuty: {
        filters: [{type: "committee", value: "VZV"}],
      },
    },
    {
      id: "CVZV2",
      name: "Předsedající VZV, Nová Pravice",
      body: BodyType.Senate,
      party: Party.Republican,
      fractionId: "NOVPRAV",
      state: State.Florida,
      ratingDuty: {
        filters: [{type: "committee", value: "VZV"}],
      },
    },
    {
      id: "CVZV3",
      name: "Předsedající VZV, Liberální Republikán",
      body: BodyType.Senate,
      party: Party.Republican,
      fractionId: "LIBREP",
      state: State.Wyoming,
      ratingDuty: {
        filters: [{type: "committee", value: "VZV"}],
      },
    },
    {
      id: "CVOS1",
      name: "Předsedající VOS, Progresivista",
      body: BodyType.Senate,
      party: Party.Democrat,
      fractionId: "PROG",
      state: State.Pennsylvania,
      ratingDuty: {
        filters: [{type: "committee", value: "VOS"}],
      },
    },
    {
      id: "CVOS2",
      name: "Předsedající VOS, Klub za Svobodu",
      body: BodyType.Senate,
      party: Party.Republican,
      fractionId: "SVOB",
      state: State.Texas,
      ratingDuty: {
        filters: [{type: "committee", value: "VOS"}],
      },
    },
    {
      id: "CVDětR1",
      name: "Předsedající VDětR, Progresivista",
      body: BodyType.Senate,
      party: Party.Democrat,
      fractionId: "PROG",
      state: State.NewHampshire,
      ratingDuty: {
        filters: [{type: "committee", value: "VDětR"}],
      },
    },
    {
      id: "CVDětR2",
      name: "Předsedající VDětR, Liberální Republikán",
      body: BodyType.Senate,
      party: Party.Republican,
      fractionId: "LIBREP",
      state: State.Oregon,
      ratingDuty: {
        filters: [{type: "committee", value: "VDětR"}],
      },
    },
  ],
  financeSources: [
    {id: "fin.CoC", name: "Chamber of Commerce", orgPosition: {id: "CoC", name: "Chamber of Commerce", body: BodyType.Lobby, ratingDuty: {filters: [{type: "body", value: BodyType.Lobby}]}}},
    {id: "fin.NCN", name: "National Council of Nonprofits", orgPosition: {id: "NCN", name: "National Council of Nonprofits", body: BodyType.Lobby, ratingDuty: {filters: [{type: "body", value: BodyType.Lobby}]}}},
  ],
  additionalRoles: [
    {id: "VJŘmember", name: "Člen Výboru pro jednací řád"},
    {id: "VJŘmember2", name: "Členka výboru pro jednací řád"},
    {id: "tajRep", name: "Tajemník Republikánské strany"},
    {id: "tajDem", name: "Tajemník Demokratické strany"},
    {id: "SCOTUS", name: "Předseda SCOTUS"},
    {id: "comissionMember", name: "Člen disciplinární komise"},
  ],
};

export const model = generateModel(setup);
