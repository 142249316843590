import {initialConfig} from "@functions/config";
import {NOBODY} from "@functions/mak-types";
import {reactive} from "vue";

export const store = reactive({
  me: NOBODY,
  roleWish: {},
  service: {},
  config: initialConfig,
});
