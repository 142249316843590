import {PriceList, Service, User, UserWithService} from "@functions/mak-types";
import {serviceModel} from "@functions/static-service";

export function calculateUnpaidDebt(service: Service, user: User) : number {
  const pricingModel = serviceModel;
  let debt = 0;
  let applicablePricing = pricingModel.prices.find((pricing) => pricing.pricingGroup === user.accessLevel);

  if (!applicablePricing) {
    const allApplicable: PriceList[] = [];
    pricingModel.prices.forEach((currentValue) => {
      if (currentValue.pricingGroup < user.accessLevel) allApplicable.push(currentValue);
    });
    if (allApplicable.length === 0) throw new Error("There are no applicable priceLists for user");

    applicablePricing = allApplicable.reduce((prev, curr) => {
      return prev.pricingGroup < curr.pricingGroup ? curr : prev;
    });
    if (!applicablePricing) throw new Error("There are no applicable priceLists for user");
    // This should realistically never occur, but spares us syntax sugar around undefined value
  }

  if (service.serviceOrder.dormOrder) {
    debt += applicablePricing.fiveNights;
    debt += service.serviceOrder.dormOrder?.extraNight ? applicablePricing.extraNight : 0;
  }
  debt += service.serviceOrder.lunches ? applicablePricing.lunches : 0;
  debt += service.serviceOrder.publicTransport ? applicablePricing.publicTransport : 0;
  debt += applicablePricing.attendanceFee;

  service.payments?.forEach((payment) => {
    debt -= payment.amount;
  });

  service.correctingPayments?.forEach((payment) => {
    debt += Number(payment.amount);
  });

  return debt;
}

export function calculateDebt(service: Service, user: User) : number {
  const pricingModel = serviceModel;
  let debt = 0;
  let applicablePricing = pricingModel.prices.find((pricing) => pricing.pricingGroup === user.accessLevel);

  if (!applicablePricing) {
    const allApplicable: PriceList[] = [];
    pricingModel.prices.forEach((currentValue) => {
      if (currentValue.pricingGroup < user.accessLevel) allApplicable.push(currentValue);
    });
    if (allApplicable.length === 0) throw new Error("There are no applicable priceLists for user");

    applicablePricing = allApplicable.reduce((prev, curr) => {
      return prev.pricingGroup < curr.pricingGroup ? curr : prev;
    });
    if (!applicablePricing) throw new Error("There are no applicable priceLists for user");
    // This should realistically never occur, but spares us syntax sugar around undefined value
  }

  if (service.serviceOrder.dormOrder) {
    debt += applicablePricing.fiveNights;
    debt += service.serviceOrder.dormOrder?.extraNight ? applicablePricing.extraNight : 0;
  }
  debt += service.serviceOrder.lunches ? applicablePricing.lunches : 0;
  debt += service.serviceOrder.publicTransport ? applicablePricing.publicTransport : 0;
  debt += applicablePricing.attendanceFee;

  return debt;
}

export function getApplicablePriceList(user: User) : PriceList {
  const pricingModel = serviceModel;
  let applicablePricing = pricingModel.prices.find((pricing) => pricing.pricingGroup === user.accessLevel);

  if (!applicablePricing) {
    const allApplicable: PriceList[] = [];
    pricingModel.prices.forEach((currentValue) => {
      if (currentValue.pricingGroup < user.accessLevel) allApplicable.push(currentValue);
    });
    if (allApplicable.length === 0) throw new Error("There are no applicable priceLists for user");

    applicablePricing = allApplicable.reduce((prev, curr) => {
      return prev.pricingGroup < curr.pricingGroup ? curr : prev;
    });
    if (!applicablePricing) throw new Error("There are no applicable priceLists for user");
    // This should realistically never occur, but spares us syntax sugar around undefined value
  }
  return applicablePricing;
}

export function getDebtItems(service: Service, user: User) : Map<string, number> {
  const result: Map<string, number> = new Map<string, number>();
  const priceList = getApplicablePriceList(user);
  result.set("Účastnický poplatek", priceList.attendanceFee);
  if (service.serviceOrder.dormOrder !== undefined) {
    result.set("5 nocí na koleji", priceList.fiveNights);
    if (service.serviceOrder.dormOrder.extraNight) result.set("Extra noc na koleji", priceList.extraNight);
  }
  if (service.serviceOrder.lunches) result.set("Obědy", priceList.lunches);
  if (service.serviceOrder.publicTransport) result.set("Veřejná doprava", priceList.publicTransport);
  return result;
}

export function getDebtInText(user: UserWithService): string {
  if (!user.service) return "";
  const debt = calculateDebt(user.service, user);
  if (debt === 0) return "";
  const paidDebt = debt - calculateUnpaidDebt(user.service, user);
  return "Zaplaceno: " + paidDebt + "/" + debt + " Kč";
}
