
import {defineComponent} from "vue";
import {signin} from "../helpers/authHelper";

export default defineComponent({
  name: "Landing",

  data() {
    return {
      termsDialog: false,
    };
  },
  methods: {
    toAbout() {
      // Also possible to import useRouter and store it in data. See About for example
      this.$router.push("/about");
    },
    toLogin() {
      this.$router.push("/login");
    },
    async login() {
      await signin();
      this.$router.push("/main");
    },

  },
});
