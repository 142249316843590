import {parse} from "yaml";
import {ServiceSetup, generateModel} from "./service-setup-model";

const yaml = `

# yaml-language-server: $schema=./service-schema.json
priceLists:
    - pricingGroup: 2
      attendanceFee: 1100
      fiveNights: 1000
      extraNight: 200
      lunches: 500
      publicTransport: 0
    - pricingGroup: 3
      attendanceFee: 0
      fiveNights: 0
      extraNight: 0
      lunches: 0
      publicTransport: 0

dorms:
    - name: Heyrovského
    - name: Bolevecká
`;

const setup = parse(yaml) as ServiceSetup;
export const serviceModel = generateModel(setup);
