

import {defineComponent} from "vue";
import {AccessLevel, Evaluation, EvaluationStats, Position, User, WishWithEval, minimumEvalCount} from "@functions/mak-types";
import {getAllEvaluators, getAllMyRatedRoleWishes, getEvaluationStats, getNewRoleWishToRate, getRoleOrder, getRoleWish, getSelf, updateMyEvaluation, updateUserDetails, getAllEvaluationsByAuthor} from "../client";
import Score from "../components/Score.vue";
import UserHeader from "../components/UserHeader.vue";
import {shortPositionText, coverLetterName, filterToText, bodyTypeToText} from "../cmak-ui";
import QuillContent from "../components/QuillContent.vue";
import SliderWithCounter from "../components/SliderWithCounter.vue";

export default defineComponent({
  name: "RegistrationView",
  components: {
    Score,
    UserHeader,
    QuillContent,
    SliderWithCounter,
  },
  data: () => ({
    evaluations: new Array<WishWithEval>(),
    isAdmin: false,
    stats: null as EvaluationStats | null,
    ratedEvals: new Set<number>(),
    evaluators: [] as User[],
    evaluatorDialog: false,
    detailEvaluations: [] as Evaluation[],
    detailEvaluator: null as User | null,
    minimumEvalCount: minimumEvalCount,
  }),
  computed: {
    personalAverage() {
      return this.averageScore(this.evaluations.map((e) => e.evaluation));
    },
  },
  mounted() {
    getAllMyRatedRoleWishes(this).then((wishEvals) => {
      this.evaluations = wishEvals;
      this.ratedEvals = new Set<number>();
      wishEvals.forEach((wish, index) => {
        if (!wish.evaluation.score) {
          wish.evaluation.score = {
            creativityAndRhetoric: 50,
            grammar: 50,
            structure: 50,
            credibility: 50,
          };
        } else this.ratedEvals.add(index);
      });
    });
    getSelf().then((user) => {
      this.isAdmin = user.accessLevel === AccessLevel.Admin;
      if (this.isAdmin) {
        getEvaluationStats().then((stats) => {
          if (stats) this.stats = stats;
        });
      }
    });
    getAllEvaluators(this).then((evaluators) => {
      this.evaluators = evaluators;
    });
  },
  methods: {
    getNewRoleWish() {
      getNewRoleWishToRate(this).then((wishWithEval) => {
        wishWithEval.evaluation.score = {
          creativityAndRhetoric: 50,
          grammar: 50,
          structure: 50,
          credibility: 50,
        };
        this.evaluations.push(wishWithEval);
      });
    },
    saveEvaluation(wishWithEval: WishWithEval, index: number) {
      updateMyEvaluation(wishWithEval.evaluation, this).then((evaluation) => {
        wishWithEval.evaluation = evaluation;
        this.ratedEvals.add(index);
      });
    },
    reload() {
      getAllMyRatedRoleWishes(this).then((wishEvals) => {
        this.evaluations = wishEvals;
        this.ratedEvals = new Set<number>();
        wishEvals.forEach((wish, index) => {
          if (!wish.evaluation.score) {
            wish.evaluation.score = {
              creativityAndRhetoric: 50,
              grammar: 50,
              structure: 50,
              credibility: 50,
            };
          } else this.ratedEvals.add(index);
        });
        this.evaluations = wishEvals;
      });
    },
    getHeader(wish: WishWithEval) {
      return wish.roleWish.primaryRoles ?
      `${coverLetterName(wish.roleWish.primaryRoles[0])}: ${bodyTypeToText(wish.roleWish.primaryRoles[0].body)}` :
      "Něco je špatně :-(";
    },
    showEvaluatorDialog(evaluator: User) {
      getAllEvaluationsByAuthor(evaluator.id, this).then((evaluations) => {
        this.detailEvaluations = evaluations;
        this.detailEvaluator = evaluator;
        this.evaluatorDialog = true;
      });
    },
    averageScore(evals: Evaluation[]) {
      let validEvals = 0;
      const resScore = {
        creativityAndRhetoric: 0,
        credibility: 0,
        grammar: 0,
        structure: 0,
      };
      for (let i = 0; i < evals.length; i++) {
        if (evals[i].score) {
          ++validEvals;
          resScore.creativityAndRhetoric += evals[i].score!.creativityAndRhetoric;
          resScore.grammar += evals[i].score!.grammar;
          resScore.structure += evals[i].score!.structure;
          resScore.credibility += evals[i].score!.credibility;
        }
      }
      return {
        creativityAndRhetoric: validEvals > 0 ? resScore.creativityAndRhetoric / validEvals : 0,
        credibility: validEvals > 0 ? resScore.credibility / validEvals : 0,
        grammar: validEvals > 0 ? resScore.grammar / validEvals : 0,
        structure: validEvals > 0 ? resScore.structure / validEvals : 0,
      };
    },
    shortPositionText(position: Position) {
      return shortPositionText(position);
    },
    coverLetterName(position?: Position) {
      if (!position) return "";
      return coverLetterName(position);
    },
  },
});
