import {getAuth, signInWithPopup, GoogleAuthProvider} from "firebase/auth";
import {getSelf} from "../client";
import {store} from "../store";

export async function signin(): Promise<void> {
  // Also possible to import useRouter and store it in data. See About for example
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  return signInWithPopup(auth, provider).then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential?.accessToken;
    // The signed-in user info.
    const user = result.user;
    // ...
  }).then(() =>
    getSelf().then((u) => {
      if (u) {
        store.me = u;
      }
    }))
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        console.error(`login failed :${error}`);
      });
}
