import {AccessLevel, Dorm, PriceList, ServiceModel} from "./mak-types";
import {checkAll,
  mandatory,
  numeric,
  unique} from "./validation";

interface DormsSetup {
    name: string,
}

interface PriceSetup {
    pricingGroup: AccessLevel,
    attendanceFee: number,
    fiveNights: number,
    extraNight: number,
    lunches: number,
    publicTransport: number,
}

export interface ServiceSetup {
    dorms: DormsSetup[],
    priceLists: PriceSetup[],
}

/**
 * Validates provided Service setup
 * @param {setup} setup to validate
 * @return {boolean}
 */
export function validateSetup(setup:Partial<ServiceSetup>) {
  if (!setup.priceLists || setup.priceLists.length < 1) throw new Error("missing mandatory field: priceLists");
  if (!setup.dorms || setup.dorms.length < 1) throw new Error("missing mandatory field: Dorms");

  checkAll(
      "priceLists",
      setup.priceLists,
      mandatory((p) => p.pricingGroup),
      mandatory((p) => p.attendanceFee),
      mandatory((p) => p.fiveNights),
      mandatory((p) => p.extraNight),
      mandatory((p) => p.lunches),
      mandatory((p) => p.publicTransport),
      numeric((p) => p.pricingGroup),
      numeric((p) => p.attendanceFee),
      numeric((p) => p.fiveNights),
      numeric((p) => p.extraNight),
      numeric((p) => p.lunches),
      numeric((p) => p.publicTransport),
      unique((p) => p.pricingGroup),
  );

  checkAll(
      "dorms",
      setup.dorms,
      mandatory((p) => p.name),
      unique((p) => p.name),
  );
  return setup ? true : false;
}

export function generateModel(setup:ServiceSetup):ServiceModel {
  validateSetup(setup);
  const priceLists: PriceList[] = setup.priceLists.map((pl) => ({
    pricingGroup: pl.pricingGroup,
    attendanceFee: pl.attendanceFee,
    fiveNights: pl.fiveNights,
    extraNight: pl.extraNight,
    lunches: pl.lunches,
    publicTransport: pl.publicTransport,
  }));
  const dorms: Dorm[] = setup.dorms.map((dm) => ({name: dm.name}));
  return {dorms: dorms, prices: priceLists};
}
