import {Account, AccountState, CV, Evaluation, EvaluationStats, MediaPriceList, NonFiscalMeans, PersonalInfo, Rating, RatingHints, RoleOrder, RoleReality, RoleWish, Service, TransactionEnriched, User, UserRating, UserWithPersonalInfo, UserWithRating, UserWithRoleOrder, UserWithService, WishWithEval} from "@functions/mak-types";
import {FirebaseApp} from "firebase/app";
import {Functions, getFunctions, httpsCallable, HttpsCallable} from "firebase/functions";
import {DEV_ENVIRONMENT} from "./helpers/consts";
import {AssignmentPlan} from "@functions/role-assignment";
import {AccountOperation, AccountOperationRequest} from "@functions/mak-bank";
import {BodyType} from "@functions/mak-facts";
import {MakConfig} from "@functions/config";
import _ from "lodash";

let functions:Functions|undefined;

// Work with User
let _getSelf: HttpsCallable<unknown, unknown>;
let _getAllUsers: HttpsCallable<unknown, unknown>;
let _updateUserDetails: HttpsCallable<unknown, unknown>;
let _updateCV: HttpsCallable<unknown, unknown>;
let _updateUserPosition: HttpsCallable<unknown, unknown>;
let _getAllUsersWithPosition: HttpsCallable< {bodies?:BodyType[], includeCv?:boolean}, User[]>;
let _getPersonalInfo: HttpsCallable<unknown, unknown>;
let _upsertPersonalInfo: HttpsCallable<unknown, unknown>;
let _updateIdentity: HttpsCallable< Partial<User>, User>;
let _getPresidentialCandidates: HttpsCallable<unknown, unknown>;
let _getAllUsersWithPersonalInfo: HttpsCallable<unknown, unknown>;

// Work with RoleOrder
let _getRoleOrder: HttpsCallable<unknown, unknown>;
let _createRoleOrder: HttpsCallable<unknown, unknown>;
let _updateRoleOrder: HttpsCallable<unknown, unknown>;
let _deleteRoleOrder: HttpsCallable<unknown, unknown>;
let _getAllRoleOrders: HttpsCallable<unknown, unknown>;
let _getAllUsersWithRoleOrder: HttpsCallable<unknown, unknown>;

// Work with RoleWish
let _addNewRatingToWish: HttpsCallable<unknown, unknown>;
let _getRoleWish: HttpsCallable<unknown, unknown>;
let _updateRoleWish: HttpsCallable<unknown, unknown>;

// Work with RoleReality
let _updateKillFlag: HttpsCallable<unknown, unknown>;

// Work with Evaluation
let _getAllEvaluationsOfUser: HttpsCallable<unknown, unknown>;
let _createEvaluation: HttpsCallable<unknown, unknown>;
let _getEvaluationStats: HttpsCallable<unknown, unknown>;
let _getAllEvaluationsByAuthor: HttpsCallable<unknown, unknown>;
let _updateMyEvaluation: HttpsCallable<unknown, unknown>;
let _getAllEvaluators: HttpsCallable<unknown, unknown>;

// Work with WishWithEval
let _getAllMyRatedRoleWishes: HttpsCallable<unknown, unknown>;
let _getNewRoleWishToRate: HttpsCallable<unknown, unknown>;

// Work with AssignmentPlans
let _getAllAssignmentPlansNames: HttpsCallable<unknown, unknown>;
let _getAssignmentPlanByName: HttpsCallable<unknown, unknown>;
let _storeAssignmentPlan: HttpsCallable<AssignmentPlan, AssignmentPlan>;
let _deleteAssignmentPlan: HttpsCallable<unknown, unknown>;

// Work with Service
let _updateService: HttpsCallable<unknown, unknown>;
let _getService: HttpsCallable<unknown, unknown>;
let _getAllServices: HttpsCallable<unknown, unknown>;
let _createAllServices: HttpsCallable<unknown, unknown>;
let _getGetAllUsersWithServices: HttpsCallable<unknown, unknown>;
let _getAllUsersWithServicesWithoutPersonalInfo: HttpsCallable<unknown, unknown>;
let _updateAccounting: HttpsCallable<unknown, unknown>;

// Work with Bank
let _submitAccountOperation: HttpsCallable<unknown, unknown>;
let _getMediaPriceList: HttpsCallable<unknown, unknown>;
let _updateMediaPriceList: HttpsCallable<unknown, unknown>;
let _getAccountState: HttpsCallable<unknown, unknown>;
let _exportAllTransactions: HttpsCallable<unknown, unknown>;
let _deleteFaultyTransactions: HttpsCallable<unknown, unknown>;
let _getAccessibleAccounts: HttpsCallable<unknown, unknown>;

// Config
let _getConfig: HttpsCallable<void, MakConfig>;
let _setConfig: HttpsCallable<MakConfig, void>;
let _deleteAllUsers: HttpsCallable<{password:string}, void>;

// Rating
let _getUsersWithRating: HttpsCallable<void, UserWithRating[]>;
let _getUserRating: HttpsCallable<string, UserRating>;
let _updateDailyRating: HttpsCallable<{id: string, dayNumber: number, rating: Rating}, UserRating>;
let _updateRating: HttpsCallable<{id: string, influence?: number, futureChances?: number, final?: boolean}, UserRating>;
let _getRatingHints: HttpsCallable<string, RatingHints>;

// Non fiscal means
let _getAllNonFiscalMeans: HttpsCallable<void, NonFiscalMeans[]>;
let _createNonFiscalMeans: HttpsCallable<NonFiscalMeans, NonFiscalMeans>;
let _updateNonFiscalMeans: HttpsCallable<NonFiscalMeans, NonFiscalMeans>;
let _deleteNonFiscalMeans: HttpsCallable<string, void>;
let _assignNonFiscalMeans: HttpsCallable<{toUserId:string, meansId:string}, NonFiscalMeans>;
let _getNonFiscalMeansByUserId: HttpsCallable<string, {held: NonFiscalMeans[]|undefined, assigned: NonFiscalMeans[]}>;

// Testing
let _generateUsers: HttpsCallable<unknown, unknown>;

export function initClient(app:FirebaseApp ) {
  functions = DEV_ENVIRONMENT? getFunctions() : getFunctions(app, "europe-west2");

  // Work with User
  _getSelf = httpsCallable(functions, "getSelf");
  _getAllUsers = httpsCallable(functions, "getAllUsers");
  _updateUserDetails = httpsCallable(functions, "updateUserDetails");
  _updateCV = httpsCallable(functions, "updateCV");
  _updateUserPosition = httpsCallable(functions, "updateUserPosition");
  _getAllUsersWithPosition = httpsCallable(functions, "getAllUsersWithPosition");
  _getPersonalInfo = httpsCallable(functions, "getPersonalInfo");
  _upsertPersonalInfo = httpsCallable(functions, "upsertPersonalInfo");
  _updateIdentity = httpsCallable(functions, "updateIdentity");
  _getPresidentialCandidates = httpsCallable(functions, "getPresidentialCandidates");
  _getAllUsersWithPersonalInfo = httpsCallable(functions, "getAllUsersWithPersonalInfo");

  // Work with RoleOrder
  _getRoleOrder = httpsCallable(functions, "getRoleOrder");
  _createRoleOrder = httpsCallable(functions, "createRoleOrder");
  _updateRoleOrder = httpsCallable(functions, "updateRoleOrder");
  _deleteRoleOrder = httpsCallable(functions, "deleteRoleOrder");
  _getAllRoleOrders = httpsCallable(functions, "getAllRoleOrders");
  _getAllUsersWithRoleOrder = httpsCallable(functions, "getAllUsersWithRoleOrder");

  // Work with RoleWish
  _addNewRatingToWish = httpsCallable(functions, "addNewRatingToWish");
  _getRoleWish = httpsCallable(functions, "getRoleWish");
  _updateRoleWish = httpsCallable(functions, "updateRoleWish");

  // Work with RoleReality
  _updateKillFlag = httpsCallable(functions, "updateKillFlag");

  // Work with Evaluation
  _getAllEvaluationsOfUser = httpsCallable(functions, "getAllEvaluationsOfUser");
  _createEvaluation = httpsCallable(functions, "createEvaluation");
  _getEvaluationStats = httpsCallable(functions, "getEvaluationStats");
  _getAllEvaluationsByAuthor = httpsCallable(functions, "getAllEvaluationsByAuthor");
  _updateMyEvaluation = httpsCallable(functions, "updateMyEvaluation");
  _getAllEvaluators = httpsCallable(functions, "getAllEvaluators");

  // Work with WishWithEval
  _getAllMyRatedRoleWishes = httpsCallable(functions, "getAllMyRatedRoleWishes");
  _getNewRoleWishToRate = httpsCallable(functions, "getNewRoleWishToRate");

  //  Work with AssignmentPlans
  _getAllAssignmentPlansNames = httpsCallable(functions, "getAllAssignmentPlansNames");
  _getAssignmentPlanByName = httpsCallable(functions, "getAssignmentPlanByName");
  _storeAssignmentPlan = httpsCallable(functions, "storeAssignmentPlan");
  _deleteAssignmentPlan = httpsCallable(functions, "deleteAssignmentPlan");

  // Work with Service
  _updateService = httpsCallable(functions, "updateService");
  _getService = httpsCallable(functions, "getService");
  _getAllServices = httpsCallable(functions, "getAllServices");
  _createAllServices = httpsCallable(functions, "createAllServices");
  _getGetAllUsersWithServices = httpsCallable(functions, "getGetAllUsersWithServices");
  _getAllUsersWithServicesWithoutPersonalInfo = httpsCallable(functions, "getAllUsersWithServicesWithoutPersonalInfo");
  _updateAccounting = httpsCallable(functions, "updateAccounting");

  // Work with Bank
  _submitAccountOperation = httpsCallable(functions, "submitAccountOperation");
  _getMediaPriceList = httpsCallable(functions, "getMediaPriceList");
  _updateMediaPriceList = httpsCallable(functions, "updateMediaPriceList");
  _getAccountState = httpsCallable(functions, "getAccountState");
  _exportAllTransactions = httpsCallable(functions, "exportAllTransactions");
  _deleteFaultyTransactions = httpsCallable(functions, "deleteFaultyTransactions");
  _getAccessibleAccounts = httpsCallable(functions, "getAccessibleAccounts");

  // Config
  _getConfig = httpsCallable(functions, "getConfig");
  _setConfig = httpsCallable(functions, "setConfig");
  _deleteAllUsers = httpsCallable(functions, "deleteAllUsers");

  // Rating
  _getUsersWithRating = httpsCallable(functions, "getUsersWithRating");
  _getUserRating = httpsCallable(functions, "getUserRating");
  _updateDailyRating = httpsCallable(functions, "updateDailyRating");
  _updateRating = httpsCallable(functions, "updateRating");
  _getRatingHints = httpsCallable(functions, "getRatingHints");

  // Non fiscal means
  _getAllNonFiscalMeans = httpsCallable(functions, "getAllNonFiscalMeans");
  _createNonFiscalMeans = httpsCallable(functions, "createNonFiscalMeans");
  _updateNonFiscalMeans = httpsCallable(functions, "updateNonFiscalMeans");
  _deleteNonFiscalMeans = httpsCallable(functions, "deleteNonFiscalMeans");
  _assignNonFiscalMeans = httpsCallable(functions, "assignNonFiscalMeans");
  _getNonFiscalMeansByUserId = httpsCallable(functions, "getNonFiscalMeansByUserId");

  // Testing
  _generateUsers = httpsCallable(functions, "generateUsers");
}

interface Emitter {
  $emit: (event: string, ...args: any[]) => void
}

export const clientEmits = ["client-start", "client-end", "client-fail", "client-ignore"];

const inFlight = new Map<Emitter, number>();
function callWithEmits<A>(op:()=>Promise<A>, emitter:Emitter|null = null, successMsg:string|null = null): Promise<A> {
  if (emitter) {
    inFlight.set(emitter, inFlight.get(emitter)?? 0+1);
    emitter.$emit("client-start");
    return op().then((result) => {
      inFlight.set(emitter, (inFlight.get(emitter)?? 1)-1);
      emitter.$emit("client-end", successMsg); return result;
    })
        .catch((error) => {
          inFlight.set(emitter, (inFlight.get(emitter)?? 1)-1);
          emitter.$emit("client-fail", error.message); throw error;
        });
  } else {
    return op();
  }
}

export function closeEmitter(emitter:Emitter) {
  const count = inFlight.get(emitter)?? 0;
  for (let i = 0; i < count; i++) {
    emitter.$emit("client-ignore");
  }
  inFlight.delete(emitter);
}

/*
************************
User calls
************************
*/
export function getSelf(emitter:Emitter|null = null):Promise<User> {
  return callWithEmits(()=>_getSelf().then((result)=> (result.data as User)), emitter);
}

export function getAllUsers(emitter:Emitter|null = null):Promise<User[]> {
  return callWithEmits(()=>_getAllUsers().then((result)=> (result.data as User[])), emitter);
}

export function updateUserDetails(user: Partial<User>, emitter:Emitter|null = null):Promise<User> {
  return callWithEmits(()=>_updateUserDetails(user).then((result) => result.data as User), emitter, "Údaje byly uloženy");
}

export function updateCV(data: {id: string, cv: Partial<CV>}, emitter:Emitter|null = null):Promise<User> {
  return callWithEmits(()=>_updateCV(data).then((result) => result.data as User), emitter, "Životopis byl uložen");
}

export function updateUserPosition(user: Partial<User>, emitter:Emitter|null = null):Promise<User> {
  return callWithEmits(()=>_updateUserPosition(user).then((result) => result.data as User), emitter, "Pozice byla uložena");
}

export function getAllUsersWithPosition(bodies?:BodyType[], includeCv?:boolean, emitter:Emitter|null = null):Promise<User[]> {
  return callWithEmits(()=>_getAllUsersWithPosition({bodies: bodies, includeCv: includeCv}).then((result)=> (result.data as User[])), emitter);
}

export function getPersonalInfo(id: string, emitter:Emitter|null = null):Promise<PersonalInfo> {
  return callWithEmits(()=>_getPersonalInfo(id).then((result)=> (result.data as PersonalInfo)), emitter);
}

export function upsertPersonalInfo(personalInfo: Partial<PersonalInfo>, emitter:Emitter|null = null):Promise<PersonalInfo> {
  return callWithEmits(()=>_upsertPersonalInfo(personalInfo).then((result)=> result.data as PersonalInfo), emitter);
}
export function updateIdentity(patch:Partial<User>, emitter:Emitter|null = null):Promise<User> {
  return callWithEmits(()=>_updateIdentity(patch).then((result)=> (result.data as User)), emitter, "Údaje byly uloženy");
}

export function getPresidentialCandidates(emitter:Emitter|null = null):Promise<User[]> {
  return callWithEmits(()=>_getPresidentialCandidates().then((result)=> result.data as User[]), emitter);
}

export function getAllUsersWithPersonalInfo(emitter:Emitter|null = null):Promise<UserWithPersonalInfo[]> {
  return callWithEmits(()=>_getAllUsersWithPersonalInfo().then((result)=> result.data as UserWithPersonalInfo[]), emitter);
}

/*
************************
RoleOrder calls
************************
*/
export function getRoleOrder(id?: string, emitter:Emitter|null = null):Promise<RoleOrder> {
  return callWithEmits(()=>_getRoleOrder(id).then((result)=> result.data as RoleOrder), emitter);
}

export function createRoleOrder(roleOrder: RoleOrder, emitter:Emitter|null = null):Promise<RoleOrder> {
  return callWithEmits(()=>_createRoleOrder(roleOrder).then((result)=> result.data as RoleOrder), emitter, "Přihláška byla vytvořena");
}

export function updateRoleOrder(roleOrder: Partial<UserWithRoleOrder>, emitter:Emitter|null = null):Promise<RoleOrder> {
  return callWithEmits(()=>_updateRoleOrder(roleOrder).then((result)=> result.data as RoleOrder), emitter, "Přihláška byla aktualizována");
}

export function deleteRoleOrder(id: string):Promise<void> {
  return _deleteRoleOrder(id).then(()=> {
    return;
  });
}

export function getAllRoleOrders():Promise<RoleOrder[]> {
  return _getAllRoleOrders().then((result)=> result.data as RoleOrder[]);
}

export function getAllUsersWithRoleOrder(emitter:Emitter|null = null):Promise<UserWithRoleOrder[]> {
  return callWithEmits(()=>_getAllUsersWithRoleOrder().then((result)=> result.data as UserWithRoleOrder[]), emitter);
}

/*
************************
RoleWish calls
************************
*/
export function getRoleWish():Promise<RoleWish> {
  return _getRoleWish().then((result)=> result.data as RoleWish);
}

export function updateRoleWish(roleWish: Partial<RoleWish>, emitter:Emitter|null = null):Promise<RoleWish> {
  return callWithEmits(()=>_updateRoleWish(roleWish).then((result)=> result.data as RoleWish), emitter, "Přihláška byla aktualizována");
}

export function addNewRatingToWish(rating: Evaluation, emitter:Emitter|null = null):Promise<Evaluation> {
  return callWithEmits(()=>_addNewRatingToWish(rating).then((result) => result.data as Evaluation), emitter, "Hodnocení bylo uloženo");
}

/*
************************
RoleReality calls
************************
*/
export function updateKillFlag(id: string, killFlag: boolean, killNote: string): Promise<RoleReality> {
  return _updateKillFlag({id: id, killFlag: killFlag, killNote: killNote}).then((result)=> result.data as RoleReality);
}

/*
************************
Evaluation calls
************************
*/
export function getAllEvaluationsOfUser(id: string):Promise<Evaluation[]> {
  return _getAllEvaluationsOfUser(id).then((result)=> result.data as Evaluation[]);
}

export function createEvaluation(evaluation: Evaluation, emitter:Emitter|null = null):Promise<void> {
  return callWithEmits(()=>_createEvaluation(evaluation).then(()=> {
    return;
  }), emitter, "Hodnocení bylo vytvořeno");
}

export function getEvaluationStats(emitter:Emitter|null = null):Promise<EvaluationStats> {
  return callWithEmits(()=>_getEvaluationStats().then((result)=> {
    return result.data as EvaluationStats;
  }), emitter);
}

export function getAllEvaluationsByAuthor(authorId: string, emitter:Emitter|null = null):Promise<Evaluation[]> {
  return callWithEmits(()=>_getAllEvaluationsByAuthor(authorId).then((result)=> result.data as Evaluation[]), emitter);
}

export function updateMyEvaluation(evaluation: Partial<Evaluation>, emitter:Emitter|null = null):Promise<Evaluation> {
  return callWithEmits(()=>_updateMyEvaluation(evaluation).then((result) => result.data as Evaluation), emitter, "Hodnocení bylo uloženo");
}

export function getAllEvaluators(emitter:Emitter|null = null):Promise<User[]> {
  return callWithEmits(()=>_getAllEvaluators().then((result)=> result.data as User[]), emitter);
}

/*
************************
WishWithEval calls
************************
*/
export function getAllMyRatedRoleWishes(emitter:Emitter|null = null):Promise<WishWithEval[]> {
  return callWithEmits(()=>_getAllMyRatedRoleWishes().then((result) => result.data as WishWithEval[]), emitter);
}

export function getNewRoleWishToRate(emitter:Emitter|null = null):Promise<WishWithEval> {
  return callWithEmits(()=>_getNewRoleWishToRate().then((result) => {
    return result.data as WishWithEval;
  }), emitter);
}

/*
************************
AssignmentPlan calls
************************
*/
export function getAllAssignmentPlansNames():Promise<string[]> {
  return _getAllAssignmentPlansNames().then((result)=> result.data as string[]);
}

export function getAssignmentPlanByName(name: string, emitter:Emitter|null = null):Promise<AssignmentPlan> {
  return callWithEmits(()=>_getAssignmentPlanByName(name).then((result)=> result.data as AssignmentPlan), emitter);
}

export function storeAssignmentPlan(assignmentPlan: AssignmentPlan, emitter:Emitter|null = null):Promise<AssignmentPlan> {
  return callWithEmits(()=>_storeAssignmentPlan(assignmentPlan).then((result)=>{
    return result.data;
  }), emitter, "Plán byl uložen");
}

export function deleteAssignmentPlan(assignmentPlan: AssignmentPlan, emitter:Emitter|null = null):Promise<void> {
  return callWithEmits(()=>_deleteAssignmentPlan(assignmentPlan).then(()=>{
    return;
  }), emitter, "Plán byl smazán");
}

/*
************************
Service calls
************************
*/
export function updateService(service: Partial<Service>, id: string, emitter:Emitter|null = null):Promise<Service> {
  return callWithEmits(()=>_updateService({service: service, id: id}).then((result)=> result.data as Service), emitter, "Servis byl uložen");
}

export function getService(id: string):Promise<Service> {
  return _getService(id).then((result)=> result.data as Service);
}

export function getAllServices():Promise<Service[]> {
  return _getAllServices().then((result)=> result.data as Service[]);
}

export function createAllServices(emitter:Emitter|null = null):Promise<void> {
  return callWithEmits(()=> _createAllServices().then(() => {
    return;
  }), emitter, "Každý účastník simulace má nyní servis");
}

export function getGetAllUsersWithServices(emitter:Emitter|null = null):Promise<UserWithService[]> {
  return callWithEmits(()=> _getGetAllUsersWithServices().then((result)=> result.data as UserWithService[]), emitter);
}

export function getAllUsersWithServicesWithoutPersonalInfo(emitter:Emitter|null = null):Promise<UserWithService[]> {
  return callWithEmits(()=> _getAllUsersWithServicesWithoutPersonalInfo().then((result)=> result.data as UserWithService[]), emitter);
}

export function updateAccounting(service: Partial<Service>, id: string, emitter:Emitter|null = null):Promise<Service> {
  return callWithEmits(()=>_updateAccounting({service: service, id: id}).then((result)=> result.data as Service), emitter, "Platby uloženy");
}

/*
************************
Bank calls
************************
*/
export function submitAccountOperation(operationRequest: AccountOperationRequest, emitter:Emitter|null = null):Promise<void> {
  return callWithEmits(()=>_submitAccountOperation(operationRequest).then(()=>undefined), emitter, "Operace byla provedena");
}

export function getMediaPriceList(emitter: Emitter|null = null): Promise<MediaPriceList> {
  return callWithEmits(()=>_getMediaPriceList().then((result)=> result.data as MediaPriceList), emitter);
}

export function updateMediaPriceList(mediaOrganizationId:string, price:number, emitter: Emitter|null = null): Promise<MediaPriceList> {
  return callWithEmits(()=>_updateMediaPriceList({mediaOrganizationId: mediaOrganizationId, price: price}).then((result)=> result.data as MediaPriceList), emitter, "Ceník byl aktualizován");
}

export function getAccountState(accountId:string, emitter: Emitter|null = null, enrich?:boolean): Promise<AccountState> {
  return callWithEmits(()=>_getAccountState({accountId: accountId, enrich: enrich}).then((result)=> result.data as AccountState), emitter);
}

export function exportAllTransactions(emitter: Emitter|null = null): Promise<TransactionEnriched[]> {
  return callWithEmits(()=>_exportAllTransactions().then((res)=> res.data as TransactionEnriched[]), emitter);
}

export function deleteFaultyTransactions(emitter: Emitter|null = null): Promise<void> {
  return callWithEmits(()=>_deleteFaultyTransactions().then(() => undefined), emitter);
}

export function getAccessibleAccounts(emitter: Emitter|null = null): Promise<{account:Account, operations:Set<AccountOperation>}[]> {
  return callWithEmits(()=>_getAccessibleAccounts().then((res)=> res.data as {account:Account, operations:Set<AccountOperation>}[]), emitter);
}

/*
************************
Config
************************
*/
export function getConfig(): Promise<MakConfig> {
  return _getConfig(null).then((result)=> result.data as MakConfig);
}

export function setConfig(newConfig: MakConfig, emitter: Emitter|null = null): Promise<void> {
  return callWithEmits(()=>_setConfig(newConfig).then(() => undefined), emitter, "Nastavení bylo uloženo");
}
export function deleteAllUsers(password: string, emitter: Emitter|null = null): Promise<void> {
  return callWithEmits(()=>_deleteAllUsers({password: password}).then(() => undefined), emitter, "NUKED");
}
/*
************************
Rating
************************
*/
export function getUsersWithRating(emitter: Emitter|null = null):Promise<UserWithRating[]> {
  return callWithEmits(()=>_getUsersWithRating().then((res)=> res.data), emitter);
}
export function getUserRating(userId:string, emitter: Emitter|null = null):Promise<UserRating> {
  return callWithEmits(()=>_getUserRating(userId).then((res)=> res.data), emitter);
}

export function updateDailyRating(ratingUpdate:{id: string, dayNumber: number, rating: Rating}, emitter: Emitter|null = null):Promise<UserRating> {
  return callWithEmits(()=>_updateDailyRating(ratingUpdate).then((res)=>res.data), emitter, "Hodnocení uloženo");
}

export function updateRating(ratingUpdate:{id: string, influence?: number, futureChances?: number, final?: boolean}, emitter: Emitter|null = null):Promise<UserRating> {
  return callWithEmits(()=>_updateRating(ratingUpdate).then((res)=>res.data), emitter, "Hodnocení uloženo");
}

export function getRatingHints(userId:string, emitter: Emitter|null = null):Promise<RatingHints> {
  return callWithEmits(()=>_getRatingHints(userId).then((res)=> res.data), emitter);
}

/*
*******
NonFiscal means
*******
*/

export function getAllNonFiscalMeans(emitter: Emitter|null = null):Promise<NonFiscalMeans[]> {
  return callWithEmits(()=>_getAllNonFiscalMeans().then((res)=>res.data), emitter);
}

export function createNonFiscalMeans(means:NonFiscalMeans, emitter: Emitter|null = null):Promise<NonFiscalMeans> {
  return callWithEmits(()=>_createNonFiscalMeans(means).then((res)=>res.data), emitter, "Nefiskální prostředek byl vytvořen");
}

export function updateNonFiscalMeans(means:NonFiscalMeans, emitter: Emitter|null = null):Promise<NonFiscalMeans> {
  return callWithEmits(()=>_updateNonFiscalMeans(means).then((res)=>res.data), emitter, "Nefiskální prostředek byl uložen");
}

export function deleteNonFiscalMeans(id:string, emitter: Emitter|null = null):Promise<void> {
  return callWithEmits(()=>_deleteNonFiscalMeans(id).then((res)=>res.data), emitter, "Nefiskální prostředek byl smazán");
}

export function assignNonFiscalMeans(assignment:{toUserId:string, meansId:string}, emitter: Emitter|null = null):Promise<NonFiscalMeans> {
  return callWithEmits(()=>_assignNonFiscalMeans(assignment).then((res)=>res.data), emitter, "Nefiskální prostředek byl udělen");
}

export function getNonFiscalMeansByUserId(id:string, emitter: Emitter|null = null):Promise<{held: NonFiscalMeans[]|undefined, assigned: NonFiscalMeans[]}> {
  return callWithEmits(()=>_getNonFiscalMeansByUserId(id).then((res)=>res.data), emitter);
}


/*
************************
Testing
************************
*/
export function generateUsers():Promise<void> {
  return _generateUsers({}).then(()=>undefined);
}
