
import {User} from "@functions/mak-types";
import {store} from "../store";
import {defineComponent} from "vue";
import {updateIdentity} from "../client";

export default defineComponent({
  name: "PreferredInfoForm",
  emits: ["changedInfo"],
  props: {
    fullName: {
      required: true,
      type: String,
    },
    primaryMail: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      name: this.fullName,
      prefMail: this.primaryMail,
      rules: [
        (value: any) => {
          if (value) return true;
          return "You must fill out this field.";
        },
      ],
      loading: false,
    };
  },
  methods: {
    async save() {
      const form: any = this.$refs.form;
      const {valid} = await form.validate();
      if (valid) {
        this.$emit("changedInfo", {name: this.name, email: this.prefMail});
      } else {
        this.$emit("changedInfo", false);
      }
    },
  },
});
