import {
  Committee,
  CommitteePosition,
  PartisanFraction,
  HousePosition,
  LobbyistPosition,
  MultiCommitteePosition,
  PartisanPosition,
  Position,
  PositionFilter,
  SenatePosition,
  StatePosition,
  TckPosition,
  ChamberFraction,
  GovernmentPosition,
} from "./mak-types";
import {BodyType, LobbyType, Party, State} from "./mak-facts";

export class Filters {
  static ofParty(p: Party): PositionFilter {
    return {
      type: "party",
      value: p,
    };
  }
  static ofBodyType(b: BodyType): PositionFilter {
    return {
      type: "body",
      value: b,
    };
  }
  static ofCommittee(c: Committee): PositionFilter {
    return {
      type: "committee",
      value: c.id,
    };
  }
  static ofState(state: State): PositionFilter {
    return {
      type: "state",
      value: state,
    };
  }
  static ofFraction(
      fraction: PartisanFraction|ChamberFraction
  ): PositionFilter {
    return {
      type: "fraction",
      value: fraction.id,
    };
  }
  static ofLobbyType(lobbyType: LobbyType): PositionFilter {
    return {
      type: "lobbyType",
      value: lobbyType,
    };
  }

  static fromPosition(
      position:
      | TckPosition
      | SenatePosition
      | HousePosition
      | LobbyistPosition
      | GovernmentPosition
  ): PositionFilter[] {
    let result: PositionFilter[] = [];
    if (!position) {
      return [];
    }
    switch (position.body) {
      case BodyType.Government: {
        const p = position as GovernmentPosition;
        result = [
          Filters.ofBodyType(p.body),
          ...(p.availableCommittees??[]).map(Filters.ofCommittee),
        ];
        break;
      }
      case BodyType.House: {
        const p = position as HousePosition;
        result = [
          Filters.ofParty(p.party),
          Filters.ofBodyType(p.body),
          Filters.ofCommittee(p.committee),
          Filters.ofState(p.state),
          Filters.ofFraction(p.fraction),
          ...[p.secondaryFraction].filter((sf)=>sf?true:false).map((sf)=>sf!).map(Filters.ofFraction),
        ];
        break;
      }
      case BodyType.Senate: {
        const p = position as SenatePosition;
        result = [
          Filters.ofParty(p.party),
          Filters.ofBodyType(p.body),
          Filters.ofCommittee(p.committee),
          Filters.ofState(p.state),
          Filters.ofFraction(p.fraction),
          ...[p.secondaryFraction].filter((sf)=>sf?true:false).map((sf)=>sf!).map(Filters.ofFraction),
        ];
        break;
      }
      case BodyType.Lobby: {
        const p = position as LobbyistPosition;
        result = [
          Filters.ofBodyType(p.body),
          ...(p.availableCommittees??[]).map(Filters.ofCommittee),
          Filters.ofLobbyType(p.organization.type),
        ];
        break;
      }
    }

    return result;
  }

  static matcher(f:PositionFilter):(p:Position)=>boolean {
    switch (f.type) {
      case "body":
        return (p) => p.body == (f.value as BodyType);
      case "committee":
        return (p: Position) =>{
          if (p.body in [BodyType.Government||BodyType.Lobby]) {
            return (p as MultiCommitteePosition).availableCommittees.some(
                (c) => c.id == f.value
            );
          } else {
            return (p as Partial<CommitteePosition>).committee?.id==f.value;
          }
        };
      case "fraction":
        return (p: Position) =>
          (p as Partial<SenatePosition | HousePosition>)
              .fraction?.id == f.value ||
          (p as Partial<SenatePosition | HousePosition>)
              .secondaryFraction?.id == f.value;
      case "lobbyType":
        return (p: Position) =>
          (p as Partial<LobbyistPosition>).organization?.type == f.value;
      case "party":
        return (p: Partial<PartisanPosition>) =>
          p.party == f.value;
      case "state":
        return (p: Partial<StatePosition>) => p.state == f.value;
    }
  }
}
