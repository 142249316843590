import {Cache} from "@functions/cache";
import {MakConfig} from "@functions/config";
import {getConfig} from "./client";
import {store} from "./store";

export const UiCachedConfig = new Cache<MakConfig>(()=>getConfig().then((cfg)=>{
  store.config=cfg;
  return cfg;
}));

export async function initConfig() {
  await UiCachedConfig.get();
}
