import {FirebaseOptions} from "firebase/app";

export function getAppFireBaseConfig():FirebaseOptions {
  const deployments = {
    "Production": ["https://registrace.modelkongresu.cz", "https://makak-3000.firebaseapp.com", "https://makak-3000.web.app"],
    "Staging": ["https://amkon-stage.firebaseapp.com", "https://amkon-stage.web.app"],
    "Devel": ["https://amkon-devel.firebaseapp.com", "https://amkon-devel.web.app"],
  };

  const deployment = Object.entries(deployments).find(([d, origins]) => origins.includes(window.location.origin));

  if (deployment) {
    switch (deployment[0]) {
      case "Devel":
        return {
          apiKey: "AIzaSyB5NAc-75p6X18GFpah-wOI6M9PAzICQco",
          authDomain: "amkon-devel.firebaseapp.com",
          projectId: "amkon-devel",
          storageBucket: "amkon-devel.appspot.com",
          messagingSenderId: "874860197772",
          appId: "1:874860197772:web:59e34af66af4bd4c6e88a9",
          measurementId: "G-D9P9LKSDRP",
        };
      case "Staging": return {
        apiKey: "AIzaSyBm2Od5Rhig1-XB6sFg6WOXXTmAT0E09h4",
        authDomain: "amkon-stage.firebaseapp.com",
        projectId: "amkon-stage",
        storageBucket: "amkon-stage.appspot.com",
        messagingSenderId: "272605444424",
        appId: "1:272605444424:web:fd98f990caf916017f9b7d",
        measurementId: "G-D9L1QM0SF1",
      };
      case "Production": return {
        apiKey: "AIzaSyBZJ0j7zjxJcCeqGCwlDfvSE1CHhHFmJhA",
        authDomain: "makak-3000.firebaseapp.com",
        projectId: "makak-3000",
        storageBucket: "makak-3000.appspot.com",
        messagingSenderId: "1008878120101",
        appId: "1:1008878120101:web:7d5cfad7abda428480714c",
        measurementId: "G-RY33ZP9PXF",
      };
      default: return {};
    }
  } else {
    return {
      apiKey: "AIzaSyB5NAc-75p6X18GFpah-wOI6M9PAzICQco",
      authDomain: "amkon-devel.firebaseapp.com",
      projectId: "amkon-devel",
      storageBucket: "amkon-devel.appspot.com",
      messagingSenderId: "874860197772",
      appId: "1:874860197772:web:59e34af66af4bd4c6e88a9",
      measurementId: "G-D9P9LKSDRP",
    };
  }
}
