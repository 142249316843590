import {BodyType, bodyTypes,
  Chamber,
  chambers,
  LobbyType, lobbyTypes, parties, Party, State, states} from "./mak-facts";
import {filterTypes, PositionFilter} from "./mak-types";

import {checkAll,
  mandatory,
  numeric,
  oneOf,
  references,
  unique} from "./validation";


/**
 * AmKon setup
 * */
export interface ModelSetup {
  governingParty: Party;
  government: GovernmentPositionSetup[];
  democraticParty: PartySetup;
  republicanParty: PartySetup;
  /**
   * committees to use
   */
  committees: CommitteeSetup[];
  departments: DepartmentSetup[];
  lobbyOrganizations: LobbyOrganizationSetup[];
  orgLobbyOrganizations: LobbyOrganizationSetup[];
  mediaOrganizations: MediaOrganizationSetup[];
  partisanFractions: PartisanFractionSetup[];
  chamberFractions: ChamberFractionSetup[];
  states: StateSetup[];
  orgBranches: OrgBranchSetup[];
  investigators: OrgPositionSetup[];
  financeSources: SourceSetup[];
  otherOrgPositions: OrgPositionSetup[];
  additionalRoles: AdditionalRoleSetup[];
}

interface GovernmentPositionSetup
 {
  id: string,
  name: string,
  political: boolean,
  committees: string[]
}

interface CommitteeSetup {
    id: string,
    name: string,
    chamber: Chamber
}


interface DepartmentSetup {
    id:string,
    name: string,
    committees: string[]
}

interface LobbyOrganizationSetup {
    id: string,
    name: string,
    type: LobbyType,
    headCount?: number
}

interface MediaOrganizationSetup {
    id: string,
    name: string,
    headCount?: number
    leadOrgRole: OrgPositionSetup,
}

interface PartisanFractionSetup {
    id: string;
    name: string;
    party: Party;
  }
interface ChamberFractionSetup {
    id: string;
    name: string;
    chamber: Chamber;
}

interface StateSetup {
  state: State;
  senators: {
    party: Party;
    fractionId: string;
    secondaryFractionId?: string;
    committeeId: string;
  }[];
  representatives:{
    party: Party;
    fractionId: string;
    secondaryFractionId?: string;
    committeeId: string;
  }[]
}

interface RatingDutySetup {
  filters: PositionFilter[]
}

interface OrgPositionSetup {
    id: string,
    name: string,
    body: BodyType,
    party?: Party,
    state?: State,
    fractionId?: string,
    secondaryFractionId?: string,
    committeeId?: string,
    ratingDuty?: RatingDutySetup
}

interface OrgBranchSetup {
  id: string,
  name: string,
  teams: {
    id: string,
    name: string,
    ratingDuty?:RatingDutySetup,
    evaluationDuty?:boolean,
    accountingDuty?:boolean,
    registrationDuty?: boolean,
  }[]
}

interface AdditionalRoleSetup {
  name: string,
  id: string,
}

interface SourceSetup {
  //  ** Name of finance source, e.g. Chamber of Commerce */
  id: string,
  name: string,
  orgPosition: OrgPositionSetup,
}

interface PartySetup {
  // party leader roles have access to party account
  partyLeaderOrgRoles: OrgPositionSetup[],
  partyLeaderAdditionalRoles: AdditionalRoleSetup[],
}

/**
 * Validates provided Model setup
 * @param {setup} setup to validate
 * @return {boolean}
 */
export function validateSetup(setup:Partial<ModelSetup>) {
  if (!(setup.governingParty &&
    parties.includes(setup.governingParty))) {
    throw new Error(`invalid governing party:${setup.governingParty}`);
  }

  if (!setup.committees) {
    throw new Error("missing mandatory field: commitees");
  }

  if (!setup.government) {
    throw new Error("missing mandatory field: government");
  }

  if (!setup.democraticParty) {
    throw new Error("missing mandatory field: democraticParty");
  }

  if (!setup.republicanParty) {
    throw new Error("missing mandatory field: republicanParty");
  }

  checkAll(
      "parties",
      [setup.democraticParty, setup.republicanParty],
      mandatory((p)=>p.partyLeaderOrgRoles),
      mandatory((p)=>p.partyLeaderAdditionalRoles),
      (p)=>{
        checkAll(
            `${p} party leaders org roles`,
            p.partyLeaderOrgRoles,
            oneOf((r) => r.body, [BodyType.House, BodyType.Senate]),
            (r)=>checkOrgPosition(r, setup)
        );
        checkAll(
            `${p} party leaders additional roles`,
            p.partyLeaderAdditionalRoles,
            mandatory((r)=>r.id),
            mandatory((r)=>r.name),
            unique((r)=>r.id)
        );
      },
  );

  checkAll(
      "government",
      setup.government,
      mandatory((p) => p.name),
      mandatory((p) => p.id),
      unique((p) => p.id),
      unique((p) => p.name),
      references((p) => p.committees, setup.committees.map((c)=>c.id))
  );

  checkAll(
      "committee",
      setup.committees,
      mandatory((c) => c.id),
      mandatory((c) => c.chamber),
      mandatory((c) => c.name),
      unique((c) => c.id, "id"),
      oneOf((c)=>c.chamber, chambers)
  );

  if (!setup.departments) {
    throw new Error("missing mandatory field: departments");
  }

  checkAll(
      "department",
      setup.departments,
      mandatory((d) => d.name),
      mandatory((d) => d.id),
      unique((d) => d.id),
      references((d) => d.committees, setup.committees.map((c)=>c.id))
  );

  if (!setup.lobbyOrganizations) {
    throw new Error("missing mandatory field: lobbyOrganizations");
  }

  checkAll(
      "lobby organization",
      setup.lobbyOrganizations,
      mandatory((lo) => lo.name),
      mandatory((lo) => lo.id),
      oneOf((lo)=>lo.type, lobbyTypes),
      numeric((lo)=>lo.headCount),
      unique((lo) => lo.id),
  );

  if (!setup.mediaOrganizations) {
    throw new Error("missing mandatory field: mediaOrganizations");
  }
  checkAll(
      "media organization",
      setup.mediaOrganizations,
      mandatory((mo) => mo.name),
      mandatory((mo) => mo.id),
      numeric((mo)=>mo.headCount),
      unique((mo) => mo.id),
      mandatory((mo) => mo.leadOrgRole),
      (mo) => checkOrgPosition(mo.leadOrgRole, setup),
  );
  if (!setup.partisanFractions) {
    throw new Error("missing mandatory field: partisanFractions");
  }
  if (!setup.chamberFractions) {
    throw new Error("missing mandatory field: chamberFractions");
  }
  checkAll(
      "partisanFraction",
      setup.partisanFractions,
      mandatory((f) => f.name),
      mandatory((f) => f.id),
      mandatory((f) => f.party),
      unique((f) => f.id),
      oneOf((f) => f.party, parties),
  );
  checkAll(
      "chamberFraction",
      setup.chamberFractions,
      mandatory((f) => f.name),
      mandatory((f) => f.id),
      mandatory((f) => f.chamber),
      unique((f) => f.id),
      oneOf((f) => f.chamber, chambers),
  );

  checkAll(
      "allFractions",
      [...setup.chamberFractions, ...setup.partisanFractions],
      unique((f)=>f.id)
  );

  if (!setup.states) {
    throw new Error("missing mandatory field: states");
  }
  checkAll(
      "state",
      setup.states,
      mandatory((s)=>s.state),
      (s)=>checkAll(
          `state ${s.state}:senators`,
          s.senators,
          mandatory((sen)=>sen.fractionId),
          mandatory((sen)=>sen.committeeId),
          oneOf((sen)=>sen.party, [Party.Democrat, Party.Republican]),
          references(
              (sen)=>sen.fractionId, setup.partisanFractions?.map((f)=>f.id)??[]
          ),
          references(
              (sen)=>sen.committeeId, setup.committees?.map((c)=>c.id)??[]
          ),
          (sen)=>{
            if (!(sen.party ==
          setup.partisanFractions
              ?.find((f)=>f.id==sen.fractionId)?.party)) {
              throw new Error( "party mismatch");
            }
          },
          (sen)=>{
            if (sen.secondaryFractionId &&(!(Chamber.Senate ==
          setup.chamberFractions
              ?.find((f)=>f.id==sen.secondaryFractionId)?.chamber))) {
              throw new Error( "chamber mismatch");
            }
          },
          (sen)=>{
            if (!(Chamber.Senate ==
          setup.committees?.find((f)=>f.id==sen.committeeId)?.chamber)) {
              throw new Error( "committee mismatch");
            }
          },
      ),
      mandatory((s)=>s.representatives),
      unique((s)=>s.state),
      (s)=>checkAll(
          `state ${s}:representatives`,
          s.representatives,
          mandatory((r)=>r.fractionId),
          mandatory((r)=>r.committeeId),
          oneOf((r)=>r.party, [Party.Democrat, Party.Republican]),
          references(
              ((r)=>r.fractionId),
              setup.partisanFractions?.map((f)=>f.id)??[]
          ),
          references(
              ((r)=>r.secondaryFractionId),
              setup.chamberFractions?.map((f)=>f.id)??[]
          ),
          references((r)=>r.committeeId, setup.committees?.map((c)=>c.id)??[]),
          (r)=>{
            if (!(r.party ==
              setup.partisanFractions?.find((f)=>f.id==r.fractionId)?.party)) {
              throw new Error(
                  `state ${s.state}: representative: party mismatch`);
            }
          },
          (r)=>{
            if (r.secondaryFractionId && !(Chamber.House ==
              setup.chamberFractions
                  ?.find((f)=>f.id==r.secondaryFractionId)?.chamber)) {
              throw new Error(
                  `state ${s.state}: representative: chamber mismatch`);
            }
          },
          (r)=>{
            if (!(Chamber.House ==
              setup.committees?.find((f)=>f.id==r.committeeId)?.chamber)) {
              throw new Error(
                  `state ${s.state}: representative: committee mismatch`);
            }
          },
      ),

  );

  if (!setup.otherOrgPositions) {
    throw new Error("missing mandatory field: otherOrgPositions");
  }
  checkAll("otherOrgPositions",
      setup.otherOrgPositions,
      (p)=>checkOrgPosition(p, setup)
  );

  if (!setup.orgBranches) {
    throw new Error("missing mandatory field: orgBranches");
  }
  checkAll("orgBranches",
      setup.orgBranches,
      mandatory((o)=>o.id),
      mandatory((o)=>o.name),
      unique((o)=>o.id),
      (o)=>{
        checkAll(
            "teams",
            o.teams,
            mandatory((t)=>t.id),
            mandatory((t)=>t.name),
            unique((t)=>t.id),
            (t)=>{
              if (t.ratingDuty) {
                checkRatingDuty(t.ratingDuty);
              }
            }
        );
      },
  );

  if (!setup.investigators) {
    throw new Error("missing mandatory field: investigators");
  }

  checkAll("investigators",
      setup.investigators,
      (p)=>checkOrgPosition(p, setup)
  );

  if (!setup.financeSources) {
    throw new Error("missing mandatory field: financeSources");
  }

  checkAll("financeSources",
      setup.financeSources,
      mandatory((s)=>s.name),
      mandatory((s)=>s.id),
      unique((s)=>s.id),
      (s)=>checkOrgPosition(s.orgPosition, setup)
  );

  return setup ? true : false;
}
function checkRatingDuty(ratingDuty: RatingDutySetup) {
  checkAll("ratingDuty.filters",
      ratingDuty.filters,
      oneOf((x) => x.type, filterTypes)
  );
}

function checkOrgPosition(p: OrgPositionSetup, setup: Partial<ModelSetup>) {
  checkAll(
      `orgPosition ${p.id}`,
      [p],
      mandatory((p)=>p.id),
      mandatory((p)=>p.name),
      mandatory((p)=>p.body),
      oneOf((p)=>p.body, bodyTypes),
      oneOf((p)=>p.party, parties),
      oneOf((p)=>p.state, states),
      oneOf((p)=>p.fractionId, setup.partisanFractions?.map((f)=>f.id) ??[]),
      oneOf((p)=>p.secondaryFractionId, setup.chamberFractions?.map((f)=>f.id) ??[]),
      oneOf((p)=>p.committeeId, setup.committees?.map((c)=>c.id) ??[]),
      (p)=>{
        if (p.ratingDuty) {
          checkRatingDuty(p.ratingDuty);
        }
      }
  );
}

