import {initialConfig} from "./config";
import {BodyType, Party, stableAdditionalRoles} from "./mak-facts";
import {ModelSetup, validateSetup} from "./mak-setup";
import {Model, Committee, Department, PartisanFraction, ChamberFraction, MediaOrganization, LobbyOrganization, Position, SenatePosition, HousePosition, MinisterPosition, TckPosition, LobbyistPosition, GovernmentPoliticalPosition, GovernmentPosition, OrgTeam, Account, AdditionalRole, OrgPosition, AccountType} from "./mak-types";

export function generateModel(setup:ModelSetup):Model {
  validateSetup(setup);
  const committees: Committee[] = setup.committees.map((cs) => ({
    id: cs.id,
    chamber: cs.chamber,
    name: cs.name,
  }));
  const departments: Department[] = setup.departments.map((ds) => ({
    id: ds.id,
    name: ds.name,
    availableCommittees: ds.committees.map(
        (cid) => committees.find((c) => c.id == cid)!
    ),
  }));
  const partisanFractions: PartisanFraction[] =
  setup.partisanFractions.map((fs) => ({
    id: fs.id,
    name: fs.name,
    party: fs.party,
  }));
  const chamberFractions: ChamberFraction[] =
  setup.chamberFractions.map((fs) => ({
    id: fs.id,
    name: fs.name,
    chamber: fs.chamber,
  }));

  const mediaOrganizations: MediaOrganization[] =
  setup.mediaOrganizations.map((mo) => ({
    id: mo.id,
    name: mo.name,
    headCount: mo.headCount??1,
    leadOrgRoleId: mo.leadOrgRole.id,
  }));

  const lobbyOrganizations: LobbyOrganization[] =
  setup.lobbyOrganizations.map((lo) => ({
    id: lo.id,
    name: lo.name,
    type: lo.type,
    headCount: lo.headCount??1,
  }));

  const orgLobbyOrganizations: LobbyOrganization[] =
  setup.orgLobbyOrganizations.map((lo) => ({
    id: lo.id,
    name: lo.name,
    type: lo.type,
    headCount: lo.headCount??1,
  }));


  let additionalRoles: AdditionalRole[] = [];
  if (setup.additionalRoles) {
    additionalRoles = setup.additionalRoles.map((r)=>({name: r.name, id: r.id}));
  }
  additionalRoles.push(...stableAdditionalRoles);

  function partisanFractionById(id:string) {
    return partisanFractions.find((f)=>f.id==id);
  }

  function chamberFractionById(id?:string) {
    if (!id) {
      return undefined;
    }
    return chamberFractions.find((f)=>f.id==id);
  }

  function committeeById(id:string) {
    return committees.find((f)=>f.id==id);
  }

  function positionById(id:string) {
    return positions.find((p)=>p.identifier==id);
  }

  //   function mediaOrganizationById(id:string) {
  //     return mediaOrganizations.find((o)=>o.id==id);
  //   }

  const positions:Position[] = [
    ...setup.government.map((govPosition)=>{
      if (govPosition.political) {
        const result:GovernmentPoliticalPosition = {
          body: BodyType.Government,
          name: govPosition.name,
          identifier: `GOV-${govPosition.id}`,
          party: setup.governingParty,
          availableCommittees: govPosition.committees.map((c)=>committeeById(c)!),
        };
        return result;
      } else {
        const result:GovernmentPosition = {
          body: BodyType.Government,
          name: govPosition.name,
          identifier: `GOV-${govPosition.id}`,
          availableCommittees: govPosition.committees.map((c)=>committeeById(c)!),
        };
        return result;
      }
    }),
    ...setup.states.flatMap((s)=>([
      ...s.senators.map((sen, index)=>({
        body: BodyType.Senate,
        identifier: `senate.${s.state}${(index == 0) ? "" : index+1}`,
        fraction: partisanFractionById(sen.fractionId),
        secondaryFraction: chamberFractionById(sen.secondaryFractionId),
        committee: committeeById(sen.committeeId),
        party: sen.party,
        state: s.state,
      } as SenatePosition)),
      ...s.representatives.map((r)=>({
        body: BodyType.House,
        identifier:
          `house.${s.state}.${r.party}.${r.fractionId}.${r.committeeId}`,
        fraction: partisanFractionById(r.fractionId),
        secondaryFraction: chamberFractionById(r.secondaryFractionId),
        committee: committeeById(r.committeeId),
        party: r.party,
        state: s.state,
      } as HousePosition)),
    ])),

    ...departments.map((d)=>({
      body: BodyType.Government,
      party: setup.governingParty,
      identifier:
        `gov.${d.id}`,
      department: d,
    } as MinisterPosition)),

    ...mediaOrganizations.flatMap((mo) => [...Array(mo.headCount??1).keys()].map(()=>({
      body: BodyType.TCK,
      identifier:
        `tck.${mo.id}`,
      mediaOrg: mo,
    } as TckPosition))),

    ...lobbyOrganizations.
        flatMap((lo)=>[...Array(lo.headCount??1).keys()].map(()=>({
          body: BodyType.Lobby,
          identifier:
          `lobby.${lo.id}`,
          organization: lo,
        } as LobbyistPosition))),
  ];

  const orgBranches = setup.orgBranches.map((s) => ({id: s.id, name: s.name, teams: s.teams.map((t) =>
    ({id: t.id, name: t.name, evaluationDuty: t.evaluationDuty ?? false, accountingDuty: t.accountingDuty ?? false, registrationDuty: t.registrationDuty ?? false}))}));
  const teamById = new Map<string, OrgTeam>();
  orgBranches.flatMap((b)=>b.teams).reduce((map, t)=>map.set(t.id, t), teamById);

  const orgPositions: OrgPosition[] = [];
  const parties = [{...setup.democraticParty, id: accountIdForParty(Party.Democrat), name: "Demokratická strana", party: Party.Democrat}, {...setup.republicanParty, id: accountIdForParty(Party.Republican), name: "Republikánská strana", party: Party.Republican}];

  const finesAccountId = "acc.fines";
  const campaignsAccountId = "acc.campaigns";

  setup.investigators.map((i)=> orgPositions.push({identifier: i.id, name: i.name, body: i.body, orgPosition: true, ratingDutyFilters: i.ratingDuty?.filters}));
  setup.financeSources.map((s) => orgPositions.push({identifier: s.orgPosition.id, name: s.orgPosition.name, body: s.orgPosition.body, orgPosition: true, ratingDutyFilters: s.orgPosition.ratingDuty?.filters}));
  setup.otherOrgPositions.map((o) => {
    const base : OrgPosition = {identifier: o.id, name: o.name, body: o.body, orgPosition: true};
    base.partisanFraction = partisanFractions.find((f) => f.id === o.fractionId);
    base.committee = committees.find((c) => c.id === o.committeeId);
    base.chamberFraction = chamberFractions.find((c) => c.id === o.secondaryFractionId);
    base.state = o.state;
    base.party = o.party;
    base.ratingDutyFilters = o.ratingDuty?.filters;
    orgPositions.push(base);
  });

  const accounts: Account[] = [
    ...parties.map((p)=>{
      const partyLeaderOrgRoles = p.partyLeaderOrgRoles.map((r)=>(({identifier: r.id, name: r.name, body: r.body, party: p.party, state: r.state, partisanFraction: partisanFractionById(r.fractionId??"")}) as OrgPosition));
      const additionalPartyLeadRoles = p.partyLeaderAdditionalRoles.map((r)=>(({id: r.id, name: r.name}) as AdditionalRole));
      orgPositions.push(...partyLeaderOrgRoles);
      additionalRoles.push(...additionalPartyLeadRoles);
      return {name: p.name, id: p.id, accessFilter: [...additionalPartyLeadRoles.map((r)=>r.id), ...partyLeaderOrgRoles.map((r)=>r.identifier)], type: AccountType.Entity, isTransparent: true};
    }),
    ...[...chamberFractions, ...partisanFractions].map((f)=>{
      const fractionRoleId = `frac.${f.id}`;
      additionalRoles.push({name: `Předseda frakce ${f.name}`, id: fractionRoleId});
      return {name: f.name, id: accountIdForFraction(f.id), accessFilter: [fractionRoleId], type: AccountType.Entity, isTransparent: true};
    }),
    ...lobbyOrganizations.map((lo)=> (
      {name: lo.name, id: accountIdForLobby(lo.id), accessFilter: [`lobby.${lo.id}`], type: AccountType.Entity, isTransparent: false}
    )),
    ...orgLobbyOrganizations.map((lo)=> {
      orgPositions.push({identifier: lo.id, name: lo.name, body: BodyType.Lobby, orgPosition: true});
      return {name: lo.name, id: accountIdForLobby(lo.id), accessFilter: [lo.id], type: AccountType.Entity, isTransparent: false};
    }),
    ...setup.financeSources.map((source)=> ({name: source.name, id: source.id, accessFilter: [source.orgPosition.id], type: AccountType.Source, isTransparent: false})),
    ...setup.mediaOrganizations.map((mo)=> {
      orgPositions.push({name: mo.leadOrgRole.name, identifier: mo.leadOrgRole.id, orgPosition: true, body: BodyType.TCK});
      return {name: mo.name, id: accountForMediaOrg(mo.id), accessFilter: [mo.leadOrgRole.id], type: AccountType.Entity, isTransparent: true};
    }),
    {name: "Účet federální vlády", id: finesAccountId, accessFilter: [], type: AccountType.Sink, isTransparent: true},
    {name: "Kampaně v státech Unie", id: campaignsAccountId, accessFilter: [], type: AccountType.Sink, isTransparent: true},
  ];

  return {
    governingParty: setup.governingParty,
    committees: committees,
    departments: departments,
    mediaOrganizations: mediaOrganizations,
    lobbyOrganizations: lobbyOrganizations,
    partisanFractions: partisanFractions,
    chamberFractions: chamberFractions,
    orgBranches: orgBranches,
    orgTeamById: (id:string)=>teamById.get(id),
    orgTeams: orgBranches.flatMap((b)=>b.teams),
    orgPositions: orgPositions,
    additionalRoles: additionalRoles,
    positions: positions,
    nonPersonalAccounts: accounts,
    finesAccountId: finesAccountId,
    campaignsAccountId: campaignsAccountId,
    investigatorPositionIds: setup.investigators.map((i)=> i.id),
    positionById(id) {
      return positionById(id);
    },
    committeeById(id) {
      return committeeById(id);
    },
    fractionById(id) {
      return chamberFractionById(id) ?? partisanFractionById(id);
    },
    defaultConfig: initialConfig,
  };
}

export function accountIdForParty(party:Party): string {
  switch (party) {
    case Party.Democrat:
      return "acc.dem";
    case Party.Republican:
      return "acc.rep";
  }
}

export function isPartyAccount(accountId: string): boolean {
  return (accountId === accountIdForParty(Party.Democrat) || accountId === accountIdForParty(Party.Republican));
}

export function accountIdForFraction(id: string): string {
  return `fraction.${id}`;
}

export function isFractionAccount(accountId: string): boolean {
  return accountId.startsWith("fraction.");
}

export function accountIdForLobby(id: string): string {
  return `lobby.${id}`;
}

export function isLobbyAccount(accountId: string): boolean {
  return accountId.startsWith("lobby.");
}

export function accountForMediaOrg(id: string): string {
  return `media.${id}`;
}
