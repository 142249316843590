export interface MakConfig {
    // Turns on access to the Registration section
    openRegistrations: boolean,

    // The assigned role is visible in profile page
    // It is no longer possible to sign up
    // Turns on access to the CV editor - Displays notification on main page
    assignedRolesVisible: boolean,

    // Turns on access to service section
    // Made orders are displayed in the Profile section
    serviceOrderOpen: boolean,

    // Mostly for future use
    // Could be used for public CV overview
    // Could be used for checks during the game
    // Could be used for rating attendees
    simulationInProgress: boolean,

    // Turns on access to the CVEdit view from Profile view
    // Turns on access to the CVOverview view for Admins
    cvEditorOpen: boolean,

    // Switches the home view to presidential election screen
    showPresidentialCandidates: boolean,
}

export const initialConfig = {
  assignedRolesVisible: false,
  openRegistrations: false,
  serviceOrderOpen: false,
  simulationInProgress: false,
  cvEditorOpen: false,
  showPresidentialCandidates: false,
};
