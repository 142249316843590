export function unique<T, U>(
    extractor: (a:T)=> U|undefined,
    name = "ID"): (o:T)=>void {
  const idStore = new Set<U>();
  return (o:T)=>{
    const u = extractor(o);
    if (!(u!=undefined&&(!idStore.has(u)))) {
      throw new Error(`non-unique ${name}`);
    } else {
      idStore.add(u);
    }
  };
}

export function mandatory<T, U>(extractor: (a:T)=> U|undefined): (o:T)=>void {
  return (o:T)=>{
    const u = extractor(o);
    if (u==undefined) {
      throw new Error("invalid structure");
    }
  };
}

export function oneOf<T, U>(e: (a:T)=> U|undefined, values:U[]): (o:T)=>void {
  return (o:T)=>{
    const u = e(o);
    if (!u==undefined && !values) {
      throw new Error("invalid structure");
    }
    if (!(u == undefined || values.includes(u))) {
      throw new Error("invalid value");
    }
  };
}

export function numeric<T, U>(extractor: (a:T)=> U|undefined): (o:T)=>void {
  return (o:T)=>{
    const u = extractor(o);
    if (u!=undefined&& typeof u !== "number") {
      throw new Error("invalid structure");
    }
  };
}


export function references<T, U>(
    extractor: (a:T)=> U|U[]|undefined, referencees:U[]): (o:T)=>void {
  return (o:T)=>{
    const u = extractor(o);
    if (Array.isArray(u)) {
      u.forEach((r)=>{
        if (!referencees.includes(r)) {
          throw new Error(`invalid reference: ${r}`);
        }
      });
    } else {
      if (!(u == undefined || referencees.includes(u))) {
        throw new Error(`invalid reference: ${u}`);
      }
    }
  };
}

export function checkAll<T>(
    name:string,
    elements:T[], ...checkers: Array<(o:T)=>void>) {
  elements.forEach((e, i)=>{
    checkers.forEach((c)=>{
      try {
        c(e);
      } catch (error:any) {
        throw new Error(`${name} No ${i} : ${error.message}`);
      }
    });
  });
}
