
import {generateUsers} from "../client";

export default {
  name: "AdminView",
  methods: {
    generate() {
      generateUsers();
    },
  },
};
