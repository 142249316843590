import {createRouter, createWebHashHistory, RouteRecordRaw} from "vue-router";
import WelcomeView from "../views/WelcomeView.vue";
import {getAuth} from "firebase/auth";
import {UiCachedConfig} from "../config";

export const activeRegistrationRoutes: string[] = [
  "/registration",
  "/role-preferences",
];
export const activeServiceRoutes: string[] = ["/service"];
export const activeSimulationRoutes: string[] = [];
export const adminRoutes: string[] = ["/admin", "/cvs", "/role-management"];
export const orgRoutes: string[] = ["/evalDashboard", "/user-management"];
export const attendeeRoutes: string[] = ["/congress-overview", "/bank"];

export function toAccountDetail(accountId: string) {
  router.push(`/account/${accountId}`);
}

export enum DynamicRouteNames {
  CoverLetter = "Motivační dopis",
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Welcome",
    component: WelcomeView,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/AuthView.vue"),
  },
  {
    path: "/protected",
    name: "protected",
    component: () => import("../views/ProtectedView.vue"),
    children: [
      {
        path: "/main",
        name: "Hlavní stránka",
        component: () => import("../views/MainView.vue"),
      },
      {
        path: "/registration",
        name: "Registrace",
        component: () => import("../views/RegistrationView.vue"),
      },
      {
        path: "/admin",
        name: "Admin",
        component: () => import("../views/AdminView.vue"),
      },
      {
        path: "/service",
        name: "Servis",
        component: () => import("../views/ServiceView.vue"),
      },
      {
        path: "/profile",
        name: "Profil",
        component: () => import("../views/ProfileView.vue"),
      },
      {
        path: "/cover-letter",
        name: DynamicRouteNames.CoverLetter,
        component: () => import("../views/CoverLetterView.vue"),
      },
      {
        path: "/role-preferences",
        name: "Preferované role",
        component: () => import("../views/RolePreferences.vue"),
      },
      {
        path: "/evalDashboard",
        name: "Hodnocení dopisů",
        component: () => import("../views/EvaluationView.vue"),
      },
      {
        path: "/user-management",
        name: "Správa uživatelů",
        component: () => import("../views/UserManagementView.vue"),
      },
      {
        path: "/role-management",
        name: "Management rolí",
        component: () => import("../views/RoleManagement.vue"),
      },
      {
        path: "/congress-overview",
        name: "Přehled kongresu",
        component: () => import("../views/UserGalleryView.vue"),
      },
      {
        path: "/bank",
        name: "Finance",
        component: () => import("../views/BankView.vue"),
      },
      {
        path: "/account/:id",
        name: "Účet",
        component: () => import("../views/AccountView.vue"),
      },
      {
        path: "/cv-edit",
        name: "Úprava životopisu",
        component: () => import("../views/CVEditView.vue"),
      },
      {
        path: "/non-fiscal-means",
        name: "Nefiskální prostředky",
        component: () => import("../views/NonFiscalMeansView.vue"),
      },
      {
        path: "/ratings",
        name: "Hodnocení",
        component: () => import("../views/RatingsView.vue"),
      },
      {
        path: "/app-settings",
        name: "Nastavení aplikace",
        component: () => import("../views/AppConfigView.vue"),
      },
      {
        path: "/admin-ratings",
        name: "Hodnocení - admin",
        component: () => import("../views/RatingsAdminView.vue"),
      },
    ],
  },
];

const protectedPaths: string[] = (
  routes.find((r) => r.name == "protected")?.children ?? []
).map((r) => r.path);

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// Guard certain routes

router.beforeEach(async (to, from, next) => {
  const auth = getAuth();
  if (protectedPaths.includes(to.path) && !auth.currentUser) {
    next("/");
    return;
  }
  await UiCachedConfig.get();
  next();
});

export default router;
