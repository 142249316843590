export function exportCsv<A>(items:A[], columns:[string, (a:A)=>string|number|boolean|undefined][]):string {
  const header:string=columns.map((column)=>`"${column[0]}"`).join(",");
  return header+"\n" +
    items.map((item)=>
      columns.map((column)=>column[1](item)).map((val)=>{
        switch (typeof val) {
          case "number":
            return val;
          case "boolean":
            return val?"ANO":"NE";
          case "undefined":
            return "";
          default:
            return `"${val}"`;
        }
      }).join(",")
    ).join("\n");
}
