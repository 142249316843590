import {BodyType, potusCandidateRole} from "./mak-facts";
import {Model, Account, User, AccessLevel, AccountType} from "./mak-types";

export enum AccountOperation{
    FreezeAccount,
    SeeLimitedTransactionLog,
    SeeFullTransactionLog,
    BuyMediaTime,
    Sponsor,
    Transfer,
    Deposit,
    PayFine,
    PayForCampaign,
    RevertTransaction,
}


export class Bank {
  private model: Model;
  constructor(model:Model) {
    this.model = model;
  }

  generatePersonalAccount(user:User, isTransparent?: boolean):Account {
    return {
      id: user.id,
      name: user.name,
      holder: user,
      isTransparent: isTransparent ?? false,
      type: AccountType.Personal,
      accessFilter: [],
    };
  }

  getAllowedOperations(user: User, account: Account): Set<AccountOperation> {
    const result: Set<AccountOperation> = new Set<AccountOperation>();
    if (user.accessLevel === AccessLevel.Admin) {
      result
          .add(AccountOperation.Deposit)
          .add(AccountOperation.SeeFullTransactionLog)
          .add(AccountOperation.PayFine);
    } else if (account.isTransparent) {
      result.add(AccountOperation.SeeLimitedTransactionLog);
    }

    // investigator - freeze, seeFullTransactionLog
    if (this.model.investigatorPositionIds.includes(user.position?.identifier ?? "")) {
      result.add(AccountOperation.FreezeAccount);
      result.add(AccountOperation.SeeFullTransactionLog);
    }

    // entity account, if in filter - see full transactions, buy media time?, transfer, pay fine, revert transaction

    if (account.type == AccountType.Entity && matchesFilter(user, account.accessFilter)) {
      result
          .add(AccountOperation.SeeFullTransactionLog)
          .add(AccountOperation.BuyMediaTime)
          .add(AccountOperation.Transfer)
          .add(AccountOperation.PayFine)
          .add(AccountOperation.RevertTransaction);
      if (user.position?.body == BodyType.Lobby) {
        result.delete(AccountOperation.Transfer);
        result.add(AccountOperation.Sponsor);
      }
    }

    // source account, if in filter - see full transactions, transfer
    if (account.type == AccountType.Source && matchesFilter(user, account.accessFilter)) {
      result
          .add(AccountOperation.SeeFullTransactionLog)
          .add(AccountOperation.Transfer);
    }
    // own personal - see full transactions, buy media time, pay fine, revert transaction
    // only congressmen can access their own personal accounts
    if (account.type == AccountType.Personal && account.id == user.id && (user.position?.body == BodyType.House || user.position?.body == BodyType.Senate)) {
      if ((user.additionalRoles??[]).find((role) => role.id == potusCandidateRole.id)) {
        result.add(AccountOperation.PayForCampaign);
        result.add(AccountOperation.Sponsor);
      }
      result
          .add(AccountOperation.SeeFullTransactionLog)
          .add(AccountOperation.BuyMediaTime)
          .add(AccountOperation.PayFine)
          .add(AccountOperation.RevertTransaction);
    }
    return result;
  }
}

export interface AccountOperationRequest {
    operation: AccountOperation;
    accountId: string;
    note: string;
}

export interface FreezeAccountRequest extends AccountOperationRequest {
    operation: AccountOperation.FreezeAccount;
    freeze: boolean;
}

export interface BuyMediaTimeRequest extends AccountOperationRequest {
    operation: AccountOperation.BuyMediaTime;
    mediaOrgId: string;
    numOfSeconds: number;
}

export interface TransferRequest extends AccountOperationRequest {
    operation: AccountOperation.Transfer;
    targetAccountId: string;
    amount: number;
}

export interface DepositRequest extends AccountOperationRequest {
    operation: AccountOperation.Deposit;
    amount: number;
}

export interface PayFineRequest extends AccountOperationRequest {
    operation: AccountOperation.PayFine;
    amount: number;
}

export interface SponsorAccountRequest extends AccountOperationRequest {
    operation: AccountOperation.Sponsor;
    amount: number;
    congressmanId: string;
    entityAccountId: string;
}

export interface RevertTransactionRequest extends AccountOperationRequest {
    operation: AccountOperation.RevertTransaction;
    transactionId: string;
}

export interface PaymentForCampaignRequest extends AccountOperationRequest {
  operation: AccountOperation.PayForCampaign;
  amount: number;
}

export function matchesFilter(user: User, accessFilter: string[]) {
  return accessFilter.includes(user.position?.identifier??"") || accessFilter.some((id)=>(user.additionalRoles??[]).some((role)=>role.id==id));
}

