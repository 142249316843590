import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-caption ml-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_form, {
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
      ref: "form"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              md: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                  rules: _ctx.rules,
                  label: "Plné jméno",
                  required: ""
                }, null, 8, ["modelValue", "rules"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.prefMail,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.prefMail) = $event)),
                  rules: _ctx.rules,
                  label: "Komunikační email",
                  required: ""
                }, null, 8, ["modelValue", "rules"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_v_icon, {
            icon: "mdi-alert-circle-outline",
            class: "mr-1"
          }),
          _createTextVNode(" Tyto údaje slouží ke komunikaci a následné identifikaci na akci. Prosíme, zadejte sem skutečné jméno a příjmení. ")
        ]),
        _createVNode(_component_v_card_actions, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, {
              type: "submit",
              class: "mt-2",
              color: "primary",
              onClick: _ctx.save
            }, {
              default: _withCtx(() => [
                _createTextVNode("Uložit")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 512)
  ]))
}