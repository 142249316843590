import {
  HousePosition,
  LobbyistPosition,
  Position, SenatePosition, PositionFilter, GovernmentPosition, TckPosition, OrgPosition,
  RichText,
  User,
  NonFiscalMeans,
  UserRating,
  Rating,
} from "@functions/mak-types";

import {model} from "@functions/static-model";
import {BodyType, LobbyType} from "@functions/mak-facts";
import {Delta} from "quill/core";

export function shortPositionText(positionToDisplay?: Position): string {
  if (!positionToDisplay) {
    return "";
  }
  const orgPos = positionToDisplay as OrgPosition;
  if (orgPos && orgPos.orgPosition) {
    return orgPos.name;
  }
  switch (positionToDisplay.body) {
    case BodyType.Senate: {
      let party: string;
      const position = positionToDisplay as SenatePosition;
      if (position.party === "Republican") {
        party = "REP";
      } else {
        party = "DEM";
      }
      return `Senátor za ${party}/${position.fraction.name}${position.secondaryFraction ? ` (${position.secondaryFraction.name})` : ""}, ${position.state} ve ${position.committee.name}`;
    }
    case BodyType.House: {
      const position = positionToDisplay as HousePosition;
      let party: string;
      if (position.party === "Republican") {
        party = "REP";
      } else {
        party = "DEM";
      }
      return `Poslanec za ${party}/${position.fraction.name}${position.secondaryFraction ? ` (${position.secondaryFraction.name})` : ""}, ${position.state} ve ${position.committee.name} `;
    }
    case BodyType.Government: {
      const position = positionToDisplay as GovernmentPosition;
      return position.name;
    }
    case BodyType.Lobby: {
      const position = positionToDisplay as LobbyistPosition;
      return `Lobista za ${position.organization.name}`;
    }
    case BodyType.TCK: {
      const position = positionToDisplay as TckPosition;
      return `Novinář za ${position.mediaOrg.name}`;
    }
    default:
      return "Neznámá pozice";
  }
}

export function shortOrgPositionText(position: OrgPosition) {
  if (position.chamberFraction) {
    return position.name + ", " + position.chamberFraction?.name;
  }
  return position.name;
}

export function distinctPositions(positions: Position[]): Position[] {
  return Array.from(new Map(positions.map((p) => [p.identifier, p])).values());
}

export function bodyTypeToText(bodyType: BodyType | string): string {
  switch (bodyType) {
    case BodyType.Senate: {
      return "Senát";
    }
    case BodyType.House: {
      return "Sněmovna";
    }
    case BodyType.Government: {
      return "Vláda";
    }
    case BodyType.TCK: {
      return "Novinář";
    }
    case BodyType.Other: {
      return "Ostatní";
    }
    default: {
      return bodyType;
    }
  }
}

export function filterToText(filter: PositionFilter): string {
  switch (filter.type) {
    case "body": {
      return bodyTypeToText(filter.value);
    }
    case "committee": {
      return model.committeeById(filter.value)?.name ?? filter.value;
      // pořešit mobile problem (nevejde se tam celé jméno, potřebujeme jen část za "Výbor pro " => Národní bezpečnost, Ozbrojené složky atd.)
    }
    case "fraction": {
      return model.fractionById(filter.value)?.name ?? filter.value;
    }
    case "party": {
      if (filter.value === "Republican") {
        return "Republikáni";
      } else {
        return "Demokraté";
      }
    }
    case "lobbyType": {
      switch (filter.value) {
        case LobbyType.Corp: {
          return "Korporace";
        }
        case LobbyType.Np: {
          return "Neziskovka";
        }
        case LobbyType.Un: {
          return "Odbory";
        }
        default: {
          return filter.value;
        }
      }
    }
    default: {
      return filter.value;
    }
  }
}

export function coverLetterName(position: Position): string {
  if (position.body == BodyType.House || position.body == BodyType.Senate) {
    return "Volební projev";
  } else {
    return "Průvodní dopis";
  }
}

export function timestampToDateString(timestamp: number): string {
  const date = new Date(timestamp);
  return date.getDate() + ". " + (date.getMonth() + 1) + ". " + date.getHours() + ":" + date.getMinutes().toString().padStart(2, "0");
}

export function getPartyName(party: string): string {
  if (party === "Republican") {
    return "Republikáni";
  } else {
    return "Demokraté";
  }
}

export function emptyRichText():RichText {
  return {quillOps: new Delta().ops};
}

export function richTextToDelta(text:RichText):Delta {
  return new Delta(text.quillOps);
}

export function deltaToRichText(text:Delta):RichText {
  return {quillOps: text.ops};
}

export function isInvestigator(position?: Position) {
  return model.investigatorPositionIds.includes(position?.identifier ?? "");
}

export function isJournalist(position?: Position) {
  return position?.body == BodyType.TCK;
}

export interface NonFiscalMeansEnriched extends NonFiscalMeans {
  assignmentDateString?: string,
  assignedTo?: User,
}

export function getDailyRatingsAvg(rating: UserRating): {activityScore: number, authenticityScore: number} {
  const ratedDays = rating.dailyRatings.filter((r) => r.activityScore > -1 && r.authenticityScore > -1);

  return ratedDays.reduce((acc, curr) => {
    acc.activityScore += curr.activityScore / ratedDays.length;
    acc.authenticityScore += curr.authenticityScore / ratedDays.length;

    return acc;
  }, {activityScore: 0, authenticityScore: 0});
}

export function isDayRated(dayIndex: number, dailyRatings: Rating[]) {
  return dailyRatings.at(dayIndex)?.activityScore != -1 && dailyRatings.at(dayIndex)?.authenticityScore != -1;
}

export function color(score: number): string {
  if (score <= 20) {
    return "secondary1";
  } else if (score <= 40) {
    return "secondary2";
  } else if (score <= 60) {
    return "#f7bcc7";
  } else if (score <= 80) {
    return "primary2";
  } else {
    return "primary1";
  }
}
