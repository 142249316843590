import {Filters} from "./filters";
import {AccessLevel, DiscriminationInfo, Model, Position, RoleWish, SchoolYear, UserWithRoleOrder, minimumEvalCount} from "./mak-types";

export interface AssignmentPlan {
    name:string;
    assignments:Assignment[];
    version:number;
    lastUpdated?: {seconds:number, nanos: number};
}

export interface Assignment {
    userId:string;
    positionId?: string;
    note:string;
    locked: boolean;
    applied: boolean;
}

export interface PlanStats {
  unassignedRoles: number;
  assignees: number;
  assigneesMedianHappiness: number;
  topAssigneesMedianHappiness: number; // hapiness of top 40% of assignees in order of evaluation score
}

function discrimintationScore(discriminationInfo: DiscriminationInfo):number {
  const maxRating = 100;

  // inputs gathered from user

  let yearCoefficient = 0;
  let congressCoefficient = 0;
  let politicalCoefficient = 0;
  let organisationCoefficient = 0;
  // declare the coefficients
  switch (discriminationInfo.schoolYear) {
    case SchoolYear.thirdOfHigh:
      yearCoefficient = 0.2;
      break;
    case SchoolYear.fourthOfHigh:
      yearCoefficient = 0.4;
      break;
    case SchoolYear.bachelor:
      yearCoefficient = 0.8;
      break;
    case SchoolYear.masters:
      yearCoefficient = 1;
      break;
    default:
      break;
  }
  // switch case to adjust based on year of study
  if (discriminationInfo.attendedCMAKsCount == 1) {
    congressCoefficient = 0.4;
  } else if (discriminationInfo.attendedCMAKsCount == 2) {
    congressCoefficient = 0.6;
  } else if (discriminationInfo.attendedCMAKsCount >= 3 && discriminationInfo.attendedCMAKsCount <= 7) {
    congressCoefficient = 1;
  } else if (discriminationInfo.attendedCMAKsCount > 7) {
    congressCoefficient = -1;
  }

  // switch case to adjust based on congress experience

  if (discriminationInfo.attendedSimulationsCount == 1) {
    politicalCoefficient = 0.4;
  } else if (discriminationInfo.attendedSimulationsCount == 2) {
    politicalCoefficient = 0.6;
  } else if (discriminationInfo.attendedSimulationsCount >= 3 && discriminationInfo.attendedSimulationsCount <= 10) {
    politicalCoefficient = 1;
  } else if (discriminationInfo.attendedSimulationsCount > 10) {
    politicalCoefficient = -1;
  }

  // switch case to adjust based on previous political simulation experience
  if (discriminationInfo.youthPoliticalParty == true) {
    organisationCoefficient = -1;
  }
  // penalisation for youth organisations

  const standardUnit: number = maxRating/100;
  const result: number =
            ((4 * standardUnit) * yearCoefficient) +
            ((3.5 * standardUnit) * congressCoefficient) +
            ((2.5 * standardUnit) * politicalCoefficient) +
            ((5 * standardUnit) * organisationCoefficient);
    /* Final calculation based on relative weights of elements. I am using a standardized unit since I don't know what the actual
        maximum rating will be, to maintain the same impact. If maximum rating == 100, can be just omitted.
        If the standard unit is not an integer we might be in for some floating point adventures.*/
  return result;
}


export function scalarScore(user:UserWithRoleOrder):number {
  const score = user.roleOrder.roleReality?.totalScore??{creativityAndRhetoric: 0, credibility: 0, grammar: 0, structure: 0};
  return (score.creativityAndRhetoric + score.credibility + score.grammar + score.structure) / 4 + (user.roleOrder.roleWish.discriminationInfo?discrimintationScore(user.roleOrder.roleWish.discriminationInfo):0);
}

// assigns unassgined roles to users without locked assignment and with desired letter eval count, maximizing happiness
export function autoassign(plan:AssignmentPlan, users: UserWithRoleOrder[], model:Model): AssignmentPlan {
  const assignmentsByUserId = new Map<string, Assignment>();
  plan.assignments.filter((assignment)=>(assignment.applied||assignment.locked))
      .reduce((assignmentsById, assignment)=>assignmentsById.set(assignment.userId, assignment), assignmentsByUserId);
  const freePositions = [...model.positions];
  assignmentsByUserId.forEach((assignment)=>{
    const i = freePositions.findIndex((p)=>p.identifier === assignment.positionId);
    if (i>=0) {
      freePositions.splice(i, 1);
    } else {
      console.log("ERROR: Couldn't find assigned position in free positions: " + assignment.positionId);
    }
  });

  const usersToAssign = users
      .filter((user) => !assignmentsByUserId.has(user.id)&& ((user.roleOrder.roleReality?.evalCount ?? 0) >= minimumEvalCount))
      .map((u)=>({user: u, score: scalarScore(u)}))
      .sort((a, b)=>b.score-a.score)
      .map((o)=>o.user);

  const demandMap = new Map<string, number>();
  usersToAssign.reduce((demand, user)=>{
    for ( const pos of freePositions) {
      let posDemand = demandMap.get(pos.identifier)??0;
      if (user.roleOrder.roleWish.primaryRoles?.find((p)=>p?.identifier==pos.identifier)) {
        posDemand++;
      }
      for (const f of user.roleOrder.roleWish.secondaryRoleFilters??[]) {
        if (Filters.matcher(f)(pos)) {
          posDemand+=0.1;
        }
      }
      demandMap.set(pos.identifier, posDemand);
    }
    return demand;
  }, demandMap);

  freePositions.sort((p1, p2)=>(demandMap.get(p1.identifier)??0)-(demandMap.get(p2.identifier)??0));

  const newAssignments: Assignment[] = usersToAssign.map((user) => {
    if (user.roleOrder.roleWish.primaryRoles) {
      for (const role of user.roleOrder.roleWish.primaryRoles) {
        const i = freePositions.findIndex((p)=>p.identifier==role.identifier);
        if (i!=-1) {
          const p = freePositions.splice(i, 1);
          return {
            userId: user.id,
            positionId: p[0].identifier,
            note: "",
            locked: false,
            applied: false,
          };
        }
      }
    }
    // none of primary roles are available
    // find position by filters

    let bestPosition:Position|undefined;
    let bestHappiness = 0;
    for (const position of freePositions) {
      const happiness = calculateFilterBasedHappiness(user.roleOrder.roleWish, position);
      if (happiness>bestHappiness) {
        bestPosition = position;
        bestHappiness = happiness;
      }
    }
    if (bestPosition) {
      const i = freePositions.findIndex((p)=>p.identifier==bestPosition!.identifier);
      if (i>=0) {
        freePositions.splice(i, 1);
      }
      return {
        userId: user.id,
        positionId: bestPosition.identifier,
        note: "",
        locked: false,
        applied: false,
      };
    } else {
      if (freePositions.length>0) {
        const p = freePositions[0];
        freePositions.splice(0, 1);
        return {
          userId: user.id,
          positionId: p.identifier,
          note: "",
          locked: false,
          applied: false,
        };
      } else {
        return {
          userId: user.id,
          note: "",
          locked: false,
          applied: false,
        };
      }
    }
  });
  const newPlan:AssignmentPlan = {...plan};
  newPlan.assignments = [...newAssignments, ...assignmentsByUserId.values()];

  return newPlan;
}

// updates applied flags
// TODO: [CMAK-354] also remove assignments of roles that have been applied and are no longer valid
// TODO: also eliminate assignments to people with kill flag set to true
export function updateAssignments(plan:AssignmentPlan, users: UserWithRoleOrder[], model:Model): AssignmentPlan {
  const assignmentsByUserId = new Map<string, Assignment>();
  const positionsById = new Map<string, Position>();
  model.positions.reduce((positionsById, position)=>positionsById.set(position.identifier, position), positionsById);
  plan.assignments.reduce((assignmentsById, assignment)=>assignmentsById.set(assignment.userId, assignment), assignmentsByUserId);

  for (const user of users) {
    if (user.roleOrder.roleReality?.kill || user.accessLevel >= AccessLevel.Organizer || (user.position && !positionsById.has(user.position.identifier))) {
      assignmentsByUserId.delete(user.id);
      continue;
    }
    if (user.position) {
      const assignment = assignmentsByUserId.get(user.id);
      if (assignment) {
        assignment.positionId = user.position.identifier;
        assignment.applied = true;
        assignment.locked = true;
      } else {
        const a = {
          positionId: user.position.identifier,
          userId: user.id,
          note: "",
          locked: true,
          applied: true,
        };
        plan.assignments.push(a);
        assignmentsByUserId.set(user.id, a);
      }
    } else {
      const assignment = assignmentsByUserId.get(user.id);
      if (assignment) {
        assignment.applied = false;
      } else {
        const a = {
          userId: user.id,
          note: "",
          locked: false,
          applied: false,
        };
        plan.assignments.push(a);
        assignmentsByUserId.set(user.id, a);
      }
    }
  }

  for (let i = 0; i < plan.assignments.length; i++) {
    if (!users.some((user) => user.id === plan.assignments[i].userId && !user.roleOrder.roleReality?.kill)) {
      plan.assignments.splice(i, 1);
    }
    // TODO check that the position of the assignment is still available
  }
  const freePositions = [...model.positions];

  for (const assignment of plan.assignments.filter((a) => a.applied&&a.positionId)) {
    const i = freePositions.findIndex((p)=>p.identifier === assignment.positionId);
    if (i>=0) {
      freePositions.splice(i, 1);
    } else {
      throw new Error("Invalid plan - role not available: " + assignment.positionId);
    }
  }

  for (const assignment of plan.assignments.filter((a) => !a.applied&&a.positionId&&a.locked)) {
    const i = freePositions.findIndex((p)=>p.identifier === assignment.positionId);
    if (i>=0) {
      freePositions.splice(i, 1);
    } else {
      const j = plan.assignments.indexOf(assignment);
      plan.assignments.splice(j, 1);
    }
    return plan;
  }

  for (const assignment of plan.assignments.filter((a) => !a.applied&&a.positionId&&!a.locked)) {
    const i = freePositions.findIndex((p)=>p.identifier === assignment.positionId);
    if (i>=0) {
      freePositions.splice(i, 1);
    } else {
      const j = plan.assignments.indexOf(assignment);
      plan.assignments.splice(j, 1);
    }
  }
  return plan;
}

function calculateFilterBasedHappiness(roleWish: RoleWish, position: Position):number {
  let happiness = 0;
  if (roleWish.secondaryRoleFilters && roleWish.secondaryRoleFilters.length>0) {
    const happinessQuantum = 80 / (roleWish.secondaryRoleFilters.length/2*(roleWish.secondaryRoleFilters.length+1));
    let currentHappinessDelta = happinessQuantum * roleWish.secondaryRoleFilters.length;
    for (const filter of roleWish.secondaryRoleFilters) {
      if (Filters.matcher(filter)(position)) {
        happiness += currentHappinessDelta;
      }
      currentHappinessDelta -= happinessQuantum;
    }
  }
  return happiness;
}

export function calculateHappiness(roleWish:RoleWish, position?:Position):number {
  let happiness = 0;
  if (!position) {
    return 0;
  }
  if (roleWish.primaryRoles) {
    for (let i=0; i<(roleWish.primaryRoles).length; i++) {
      if (roleWish.primaryRoles[i].identifier == position.identifier) {
        return 100-(i*5);
      }
    }
  }

  if (!roleWish.secondaryRoleFilters?.length) {
    return 0;
  }

  happiness = calculateFilterBasedHappiness(roleWish, position);
  return happiness;
}

// calculates stats for the supplied plan
export function calculateStats(plan:AssignmentPlan, users:UserWithRoleOrder[], model: Model): PlanStats {
  const assignmentsByUserId = new Map<string, Assignment>();
  const assignmentsByPositionId = new Map<string, Assignment>();
  plan.assignments.reduce((assignmentsById, assignment)=>assignmentsById.set(assignment.userId, assignment), assignmentsByUserId);
  plan.assignments.reduce((assignmentsById, assignment)=>{
    if (assignment.positionId) {
      assignmentsByPositionId.set(assignment.positionId, assignment);
    }
    return assignmentsById;
  }, assignmentsByPositionId);
  const happinessByUserId = new Map<string, number>();
  users.reduce((hapMap, u)=>{
    const assignment = assignmentsByUserId.get(u.id);
    if (assignment?.positionId) {
      hapMap.set(u.id, calculateHappiness(u.roleOrder.roleWish, model.positionById(assignment.positionId)));
    } else {
      hapMap.set(u.id, 0);
    }
    return hapMap;
  }, happinessByUserId);
  const sortedHappiness = users.map((u)=>happinessByUserId.get(u.id)??0).sort((a, b) => a-b);

  const freePositions = [...model.positions];
  assignmentsByUserId.forEach((assignment)=>{
    const i = freePositions.findIndex((p)=>p.identifier === assignment.positionId);
    if (i>=0) {
      freePositions.splice(i, 1);
    }
  });

  const sortedUsers = users
      .map((u)=>({user: u, score: scalarScore(u)}))
      .sort((a, b)=>b.score-a.score)
      .map((o)=>o.user);

  let top40MedianHappiness = 0;
  const top40 = Math.floor(users.length*0.4);
  if (top40>0) {
    const top40SortedHappiness = sortedUsers.slice(0, top40)
        .map((user)=>calculateHappiness(user.roleOrder.roleWish, assignmentsByUserId.has(user.id)?model.positionById(assignmentsByUserId.get(user.id)?.positionId??""):undefined));
    top40SortedHappiness.sort((a, b)=>a-b);
    top40MedianHappiness = top40SortedHappiness[Math.floor(top40/2)];
  }
  return {
    assignees: plan.assignments.filter((a)=>a.positionId).length,
    assigneesMedianHappiness: sortedHappiness.length>0 ? sortedHappiness[Math.floor(sortedHappiness.length/2)]:0,
    topAssigneesMedianHappiness: top40MedianHappiness,
    unassignedRoles: freePositions.length,
  };
}
