import {TransactionEnriched, User, AccessLevel} from "@functions/mak-types";
import {saveAs} from "file-saver";
import {exportCsv} from "../cmak-csv";
import {timestampToDateString} from "../cmak-ui";
import {getSelf} from "../client";
import {model} from "@functions/static-model";

export function exportTransactions(transactions: TransactionEnriched[]) {
  getSelf().then((me) => {
    const blob = new Blob([exportCsv(transactions, [
      ["Čas", (a) => timestampToDateString(a.timestamp)],
      ["Částka", (a) => a.amount],
      ["Příjemce", (a) => a.targetAccountName],
      ["Odesílatel", (a) => a.originAccountName],
      ["Poznámka", (a) => a.note ?? ""],
      ["Provedl", (a) => a.requestorId],
      ["Provedl - jméno", (a) => a.requestorName ? a.requestorName : ""],
      ["Sesterská transakce - jméno příjemce", (a) => a.siblingTransactionDetail?.targetAccountName ?? ""],
    ])], {type: "text/csv;charset=utf-8"});
    saveAs(blob, "transakce.csv");
  });
}

export function isAdminOrInvestigator(user: User | undefined) {
  return (user && (user.accessLevel > AccessLevel.Organizer ||
    (user.accessLevel > AccessLevel.Attendee && user.position && model.investigatorPositionIds.includes(user.position.identifier))));
}
